/* You can add global styles to this file, and also import other style files */
@import '../../kraken-theme/src/lib/styles/main'

@import '../src/app/side-nav/side-nav.component'
@import '../src/app/canvas/canvas.component'
@import '../src/app/header/header.component'
@import '../src/app/style-guide/patterns/tile/tile.component'

@import '../src/app/style-guide/palettes/color-palettes/color-palettes.component'
@import '../src/app/style-guide/elevation/elevation.component'
@import '../src/app/web-components/buttons/buttons.component'
@import '../src/app/style-guide/iconography/icon-catalog/icon-catalog.component'
@import '../src/app/web-components/multi-select/multi-select.component'
@import '../src/app/web-components/menu-button/menu-button.component'
@import '../src/app/web-components/split-button/split-button.component'
@import '../src/app/web-components/tabs/tabs.component'
@import '../src/app/web-components/accordion/accordion.component'
@import '../src/app/web-components/modal/modal.component'
@import '../src/app/web-components/masthead/masthead.component'
@import '../src/app/web-components/footer/footer.component'
@import '../src/app/web-components/panel/panel.component'
@import '../src/app/web-components/progress-indicator/progress-indicator.component'
@import '../src/app/web-components/chip/chip.component'
@import '../src/app/web-components/slider/slider.component'
@import '../src/app/web-components/spreadsheet/spreadsheet.component'
@import '../src/app/web-components/date-picker/date-picker.component'
@import '../src/app/web-components/date-range-picker/date-range-picker.component'
@import '../src/app/legend/legend/legend.component'
@import '../src/app/login/login.component'

.main-container
    display: flex
    background: $gray-lightest

    .no-bullets
        list-style: none
        
    .inline
        display: inline-block
        
    .kn-page-title
        font-size: 42px
        font-family: Avenir-light
        font-weight: 100

    side-nav
        max-width: 250px
        height: max-content

    .canvas-container
        position: relative
        width: 100%
        top: 60px
        height: calc(100vh - 70px)
        overflow-y: auto
        padding-bottom: 50px

    .page-container
        background: $white
        margin: 25px 20px
        border: 1px solid $border-color
        border-radius: 4px

        .section-container
            padding: 0 30px

            .kn-page-title
                padding: 50px 0 25px 0

/*Design spec component*/
#designSpec
    .action-btns
        display: flex

        .action-item
            padding: 20px 15px 55px 0 


.kn-popup
    top:100px
    height: max-content
    background: white
    display: block
    position: absolute
    z-index: 1000