
#componentTabs #canvasTabs .kn-tab-content
    ::-webkit-resizer 
        background-image: url('../../../assets/ic_arrow_leftright.svg')
        background-size: 16px
        width: 65px
        height: 100px
        background-repeat: no-repeat

    .tab-demo-container
        height: 400px
        min-width: 250px
        width: 500px
        padding: 10px

        &.responsive-view
            resize: horizontal
            overflow: auto
            border-right: 1px dashed var(--border-color)
            overflow-x: hidden
            width: 500px 

        &.non-responsive
            width: 500px 
            
        .example-tabs 
            .kn-tabs
                background: none

            .kn-tab-content
                .img-container
                    width: 80%

                
        
