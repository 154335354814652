
.canvas-container #componentTabs .container .code-container
    &#kraken-masthead
        background: var(--gray-lightest)

        .kn-component
            width: 100%
            
            .initials
                background: var(--gray)
                border-radius: 50%
                width: 43px
                height: 43px
                padding: 10px
                font-family: 'Avenir-Medium'
                color: var(--ink)
                font-size: 16px
                letter-spacing: -2px
                margin-right: 0

            #initialsDropdown
                margin-left: 5px