.kn-tab-container
  .kn-tab-header
    background: $white
    
    .link
      font-size: 16px
      
    .kn-tabs
      display: flex
      list-style: none
      padding: 10px 0
      margin: 0
      width: max-content

      .tab-links

        &.hide
          visibility: hidden
          position: absolute
          z-index: -10

          .link
            visibility: hidden

            &::before
              visibility: hidden

        &.disabled
          pointer-events: none
          color: $gray-darker
          
          .link
            color: $gray

        .links
            padding: 10px 0

  .kn-tab-menu-container
    position: relative
    
    .kn-icon

      &:hover
        background: none
        color: $ink

    .link
      top: -5px

    &.hide
      visibility: hidden
      position: absolute

      .link
        display: none

.kn-tab-content
  display: none
  //animation: fadeEffect .5s
  font-family: 'Avenir-book'
  font-size: 14px
  padding: 20px


  &.active
      display: block

    

/* Go from zero to full opacity */
@keyframes fadeEffect 
  from 
    opacity: 0
  to 
    opacity: 1
