
.kn-multi-select
    * 
        box-sizing: border-box
        
    .inner
        max-width: 100%

        .kn-input-wrapper
            position: relative
            width: 100%

            .kn-mulit-select-container
                color: $ink
                border: 1px solid $gray-darker
                font-size: 14px
                font-family: 'Avenir-book'
                line-height: 1.9
                display: block
                padding: 5px 40px 5px 10px
                font-weight: 400
                line-height: 1.5
                background-color: #fff
                background-clip: padding-box
                border-radius: 0.25rem
                text-overflow: ellipsis
                overflow: hidden
                cursor: pointer

                .display-placeholder
                    color: $gray-dark
                    width: 100%
                    font-style: italic
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap

                .kn-multi-select-textbox-display
                    border: none
                    background: transparent
                    height: 26px
                    overflow: hidden
                    width: 100%
                    display: flex
                    align-items: center

                    .selected-items
                        display: block

                        &.truncate
                            overflow: hidden
                            text-overflow: ellipsis
                            width: calc(100% - 75px)
                            white-space: nowrap
                            pointer-events: none

                    &:focus
                        border: none
                        outline: none
                
                    .more-items
                        padding: 0 10px
                        color: $ink-lighter
                        min-width: 75px
                        position: absolute
                        right: 31px
                        top: 8px

                &.error
                    outline: 2px solid $knDanger
                    border: 1px solid transparent

                &.warning
                    outline: 2px solid $secondary_one
                    border: 1px solid transparent

                &.focused
                    outline: 2px solid $primary_one
                    border: 1px solid transparent
                    
                    .kn-dp-icon
                        color: $primary_one

                    &.error
                        outline: 2px solid $knDanger
                        border: 1px solid transparent

                    &.warning
                        outline: 2px solid $secondary_one
                        border: 1px solid transparent

                &.disabled
                    pointer-events: none
                    border: 1px solid $gray-dark
                    color: $gray-dark
                    background: $white

                    .kn-multi-select-textbox-display
                        color: $gray-darker

                        .more-items
                            color: $gray-darker

                    i
                        color: $gray-darker
                        pointer-events: none

                &.read-only
                    background: $gray-light
                    border: 1px solid $gray-darker
                    outline: none
                    pointer-events: none

                    i
                        color: $gray-darker
                        pointer-events: none
                        
                    .more-items
                        pointer-events: all

            .kn-multi-select-searchbox
                padding: 5px 37px 5px 10px
                width: 100%
                border-left: none
                border-top: none
                border-right: none
                border-bottom: 1px solid $border-color
                height: 38px
                font-family: 'Avenir-Book'
                font-size: 14px

                &:focus
                    outline: none

            .kn-dropdown
                min-height: auto
                overflow: hidden
                max-height: unset
                margin-top: 8px
                padding-top: 0

                .clear-icon
                    top: 5px
                
                .inner-container::-webkit-scrollbar-thumb
                    border: 2px solid #fff

                .inner-container
                    overflow-y: scroll
                    overflow-x: hidden
                    max-height: 200px

                    .kn-dropdown-item
                        display: flex
                        align-items: baseline

                        .kn-dropdown-checkbox
                            width: 31px

        .label
            &.show
                color: $primary-one

            &.error
                color: $knDanger

            &.warning
                color: $secondary_one

            &.disabled
                color: $gray-darker
