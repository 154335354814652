
$fontDisplay: 'swap'
/*Fonts*/
@font-face 
  font-family: 'Avenir-Book'
  src: url('../assets/fonts/Avenir-Book.woff')
  font-display: $fontDisplay !important

@font-face 
  font-family: 'Avenir-Black'
  src: url('../assets/fonts/Avenir-Black.woff')
  font-display: $fontDisplay !important

@font-face 
  font-family: 'Avenir-Light'
  src: url('../assets/fonts/Avenir-Light.woff')
  font-display: $fontDisplay !important

@font-face 
  font-family: 'Avenir-Medium'
  src: url('../assets/fonts/Avenir-Medium.woff')
  font-display: $fontDisplay !important

@font-face 
  font-family: 'Avenir-Heavy'
  src: url('../assets/fonts/Avenir-Heavy.woff')
  font-display: $fontDisplay !important
