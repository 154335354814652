.kn-toggle
    display: flex
    justify-content: start
    font-size: 14px
    
    &.disabled
         pointer-events: none

         .label
            color: $gray-darker

    &.label-left
        flex-direction: row-reverse
        justify-content: space-between

        .label
            margin-right: 10px
        
    .label
        position: relative
        display: inline-block
        margin-left: 20px
        font-family: 'Avenir-book'
        color: $ink

    .switch 
        position: relative
        display: inline-block
        min-width: 44px
        height: 24px

    .switch input 
        opacity: 0
        width: 0
        height: 0


    .slider 
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $gray-darker
        -webkit-transition: .4s
        transition: .4s
        border-radius: 34px

        &:hover
            box-shadow: 0 0 2px 3px $primary_one-lighter   

        &.disabled
            background-color: $gray

    .slider:before 
        position: absolute
        content: ""
        height: 20px
        width: 20px
        left: 2px
        bottom: 2px
        background-color: white
        -webkit-transition: .4s
        transition: .4s
        border-radius: 50%

        &:hover
            box-shadow: 0 0 2px 3px $primary_one-lighter  

    input:checked + .slider 
        background-color: $primary-one

        &:hover
            box-shadow: 0 0 2px 3px $primary_one-lighter  

        &.disabled
            background-color: $gray-darker

    input:checked + .slider:before 
        -webkit-transform: translateX(20px)
        -ms-transform: translateX(20px)
        transform: translateX(20px)