
// .active-btn
//     border: 1px solid --primary-btn-pressed-color !important
//     background: --primary-btn-pressed-color !important

// .hover
//     border: 1px solid var(--blue) !important 
//     background: var(--primary-btn-hover-color) !important
//     color: #fff !important

.prop-header
    display: flex
    padding: 10px 0
    font-family: 'Avenir-heavy'

.prop
    display: flex
    padding: 10px 0

    .item:first-child
        font-family: 'Avenir-heavy'

.item
    width: 15%

.icon-name
    padding-left: 10px

        