
.kn-chip
    max-width: 348px
    width: max-content
    border: 1px solid $gray-darker
    border-radius: 13px
    background: $white
    padding: 0 8px
    cursor: pointer
    
    &.compressed
        font-size: 12px
        line-height: 20px

    &.disabled
        background: $white
        border-color: $gray-darker
        pointer-events: none

        .kn-chip-body
                .kn-chip-label
                    color: $gray-darker

    &.solid
        &.disabled
            background: $cloud-gray !important
            border-color: $cloud-gray !important
            pointer-events: none

            .kn-chip-body
                .kn-chip-label
                    color: $gray-darker !important

        &.default
            border-color: $ink-light
            background: $ink-light

            .kn-chip-body
                .kn-chip-label, i
                    color: $white
                
                i
                    color: $gray-light

                    &:hover
                        color: $white

            &:hover
                border-color: $ink-lighter
                background: $ink-lighter

        &.primaryLight
            background: $primary_one-light
            border-color: $primary_one-light

            &:hover
                background: #C9E1EF
                border-color: #C9E1EF

        &.secondaryLight
            background: $secondary_one-light
            border-color: $secondary_one-light

            &:hover
                background: #FDDCB4
                border-color: #FDDCB4

        &.dangerLight
            background: $knDanger-light
            border-color: $knDanger-light

            &:hover
                background: #FCB9AC
                border-color: #FCB9AC

        &.greenLight
            background: $green-light
            border-color: $green-light

            &:hover
                background: #E8F2D0
                border-color: #E8F2D0

        &.yellowLight
            background: $yellow-light
            border-color: $yellow-light

            &:hover
                background: #FFF1AE
                border-color: #FFF1AE

        &.tealLight
            background: $teal-light
            border-color: $teal-light

            &:hover
                background: #CDF2F2
                border-color: #CDF2F2

        &.indigoLight
            background: $indigo-light
            border-color: $indigo-light

            &:hover
                background: #CAD1F9
                border-color: #CAD1F9

        &.purpleLight
            background: $purple-light
            border-color: $purple-light

            &:hover
                background: #ECDFFF
                border-color: #ECDFFF

        &.primaryLighter
            background: $primary_one-lighter
            border-color: $primary_one-lighter

            &:hover
                background: #D7E8F3
                border-color: #D7E8F3

        &.secondaryLighter
            background: $secondary_one-lighter
            border-color: $secondary_one-lighter

            &:hover
                background: #FEF1E1
                border-color: #FEF1E1

        &.dangerLighter
            background: $knDanger-lighter
            border-color: $knDanger-lighter

            &:hover
                background: #FEEDE8
                border-color: #FEEDE8

        &.greenLighter
            background: $green-lighter
            border-color: $green-lighter

            &:hover
                background: #F2F8E4
                border-color: #F2F8E4

        &.yellowLighter
            background: $yellow-lighter
            border-color: $yellow-lighter

            &:hover
                background: #FDF6DD
                border-color: #FDF6DD

        &.tealLighter
            background: $teal-lighter
            border-color: $teal-lighter

            &:hover
                background: #DCF6F6
                border-color: #DCF6F6

        &.indigoLighter
            background: $indigo-lighter
            border-color: $indigo-lighter

            &:hover
                background: #EAEDFD
                border-color: #EAEDFD

        &.purpleLighter
            background: $purple-lighter
            border-color: $purple-lighter

            &:hover
                background: #F1E8FF
                border-color: #F1E8FF

    &:hover
        background: $primary_one-lightest
        border-color: $primary_one

    .kn-chip-body
        display: flex
        align-items: center

        .kn-chip-label
            font-family: 'Avenir-Heavy'
            color: $ink
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        i
            padding-left: 10px
            color: $ink-light
            transition: $kn-transition-200

            &:hover
                color: $primary_one