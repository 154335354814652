
.kn-modal
    display: none
    z-index: 1000
    position: fixed
    top:0
    height: 100%
    width: 100%
    overflow-y: auto

    &.kn-modal-open
        display: block

    .kn-modal-dialog
        position: relative
        margin: 0 auto
        left: 0
        right: 0
        top: 100px
        height: auto
        width: 656px
        max-height: 710px
        box-shadow: $box-shadow-elv-8

        &.modal-lx
            width: 1040px

        &.modal-lg
            width: 750px

        &.modal-sm
            width: 480px

        &.kn-warning-modal
            .modal-header
                background: $secondary_one

                .close-modal
                    &:hover
                        background: $secondary_one-dark

        &.kn-danger-modal
            .modal-header
                background: $knDanger

                .close-modal
                    &:hover
                        background: $knDanger-dark

            .kn-modal-error
                border-top: 1px solid #D18583

        .modal-header
            background: $primary_one-dark
            position: relative
            height: 56px
            color: $white
            border-radius: 0
            align-items: center
            display: flex
            justify-content: space-between
            padding: 0 15px
            border-top-right-radius: $border-radius
            border-top-left-radius: $border-radius

            .header-text
                position: absolute
                right: 42px
                font-size: 16px
                font-family: 'Avenir-Book'
                margin-right: 20px

            .modal-title
                font-weight: normal
                font-size: 24px
                margin-left: 10px

            .close-modal
                font-size: 23px
                cursor: pointer
                padding: 4px
                border-radius: 50%
                width: 30px
                text-align: center
                transition: .1s
                
                &:hover
                    background: $primary_one-darker

        .kn-modal-error
            background: $knDanger
            color: $white
            border-left: 1px solid $knDanger
            border-right: 1px solid $knDanger
            padding: 8px 22px
            display: flex

            .error-icon
                padding-right: 10px

            .error-mess
                width: 80%
                word-break: break-word

                a
                    color: $white
                    text-decoration: underline
                    font-family: 'Avenir-Heavy'

        .modal-content
            display: flex
            flex-direction: column
            border: none
            color: $ink
            margin: 0 auto
            padding: 20px 20px 20px 10px
            height: 100%
            background: $white
            border-left: 1px solid $border-color
            border-right: 1px solid $border-color
            border-bottom: 1px solid $border-color
            border-bottom-left-radius: $border-radius
            border-bottom-right-radius: $border-radius

            .inner-container
                position: relative
                flex-grow: 1
                max-height: 538px
                overflow-y: scroll
                margin-right: -10px
                padding-left: 10px
                padding-bottom: 10px

                .kn-modal-info-text
                    margin-bottom: 20px

            .kn-modal-footer
                padding: 20px 0 10px 10px
                display: flex
                justify-content: space-between
                
                .col-right
                    display: flex
                    flex: 1
                    justify-content: flex-end

                    kn-button
                        margin: 0 8px
                    
                    kn-button:last-child
                        margin: 0 0 0 8px

        .modal-errors
            position: relative
            height: 0
            opacity: 0
            background: $knDanger
            color: #fff
            padding: 5px 20px
            word-break: break-word

            a
                color: #fff
                text-decoration: underline

        .show-errors
            height: auto
            animation: showErrorMsg 
            animation-fill-mode: forwards
            animation-duration: .8s

        .modal-body
            font-size: 14px
            padding: 0

            .delete-container
                padding: 20px
                font-size: 14px

            .modal-footer
                border-top: none //30px 20 padding
                bottom: 0
                width: 100%
                position: relative
                padding: 30px 20px

                .back-btn
                    position: absolute
                    left: 20px

.kn-modal-backdrop
  position: fixed
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  background: $ink-lighter
  opacity: .5
  z-index: 900

//modal custom classes
.lh-modal-xl
    .modal-content
        width: 780px

.lh-modal-lg
    .modal-content
        width: 656px

.lh-modal-sm
    .modal-content
        width: 480px



