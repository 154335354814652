
/*Page titles and Heading*/
.kn-headline-42
  font-size: 42px
  font-family: Avenir-light
  color: $ink

.kn-headline-30
  font-size: 30px
  font-family: Avenir-Medium
  color: $ink

.kn-headline-24
  font-size: 24px
  font-family: Avenir-Book
  color: $ink

.kn-headline-24-med
  font-size: 24px
  font-family: Avenir-Medium
  color: $ink

.kn-headline-24-heavy
  font-size: 24px
  font-family: Avenir-Heavy
  color: $ink

.kn-subtitle-16
  font-size: 16px
  font-family: Avenir-Medium
  color: $ink

.kn-subtitle-14
  font-size: 14px
  font-family: Avenir-Heavy
  text-transform: uppercase
  color: $ink

.kn-responsive-img
  width: 100%
  height: auto
  