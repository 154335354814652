
//progress bar
.kn-progress-indicator
    display: flex
    justify-content: space-between
    flex-direction: column
    padding: 0 20px
    align-items: start
    line-height: 15px
    padding: 0
    min-width: 160px

    .kn-progress-label
        font-family: 'Avenir-Heavy'
        max-width: 100%
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        color: $ink

    .kn-progress-header
        display: flex
        justify-content: space-between
        width: 100%
        align-items: center
        padding-bottom: 3px

        .kn-progress-sub-text
            max-width: 100%
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            margin-right: 10px
            color: $ink

        .kn-progress-percentage
            margin-left: auto
            display: flex
            align-items: center
            height: 25px
            color: $ink

            .kn-progress-error
                margin-right: 20px
                color: $knDanger
                display: flex
                align-items: center

                i
                    color: $ink-light
                    margin-right: 10px

    .kn-progress-container
        width: 100%
        height: 10px
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 10px
        background: $gray
        overflow: hidden

        .kn-progress
            background: $primary_one
            border-top-right-radius: 5px
            border-bottom-right-radius: 5px
            width: 0
            transition: $kn-transition-250
            height: 100%
    
    &.error
        .kn-progress-container
            .kn-progress
                background: $knDanger
    
    &.warning
        .kn-progress-container
            .kn-progress
                background: $secondary_one
    
    &.success
        .kn-progress-container
            .kn-progress
                background: $green

    &.disabled
        .kn-progress-container
            .kn-progress
                background: $gray-darker

    &.kn-progress-inline
        flex-direction: row-reverse
        align-items: center
        min-width: 100px

        .kn-progress-header
            justify-content: flex-start

            .kn-progress-percentage
                margin: 0
                padding-left: 16px

            