

/*Main button class*/
.kn-btn
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    width: max-content
    padding: 7px 16px
    cursor: pointer
    text-transform: uppercase
    font-size: $primary-btn-font-size
    font-family: 'Avenir-Heavy'
    text-decoration: none
    min-width: 132px

    &:hover
        transition: $kn-transition-200
        
    &.kn-icon-only
        min-width: unset !important

    &:has(.btn-text) .icon-left
        padding-right: 8px

    i
        font-size: $btn-icon-size

    .icon-right
        transition: $kn-transition-200

        &.rotate
            transform: rotate(180deg)


    &.disabled
        pointer-events: none
        cursor: not-allowed
        font-size: $primary-btn-font-size

    &.kn-loading
        pointer-events: none
        cursor: pointer

        i:first-child
            font-family: icomoon
            font-size: 15px
            position: relative

            &:before
                content: ""
                border-radius: 50%
                width: 20px
                height: 20px
                display: block
                opacity: .25

            &:after
                content: ""
                background-size: 16px 16px
                width: 16px
                height: 16px
                display: block
                background-repeat: no-repeat
                position: absolute
                top: 2px
                left: 2px
            
.kn-btn > *
    pointer-events: none

/*Primary button class*/
.kn-primary
    border: 1px solid $primary-one-darker
    background: $primary-one-darker
    color: $white

    &.kn-btn-sm
        font-size: 12px
        padding: $btn-sm-padding
        min-width: $btn-sm
        
    &:hover
        border: 1px solid $primary-one
        background: $primary-one
        color: $white

    &.focus
        outline: none
        box-shadow: inset 0 0 0 2px $primary_one
        background: $primary-one

    &:active, &.active //pressed
        border: 1px solid $primary-one-med-light
        background: $primary-one-med-light
        

    &.kn-loading
        box-shadow: inset 0 0 0 2px $primary-one-darker
        background: $primary-one
        color: $white

        i:first-child
            &::before
                background: white
            &::after
                background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

    &.disabled
        background: $gray-light
        border-color: $gray-light 
        color: $gray-darker 

    &.warning
        background: $secondary_one
        border-color: $secondary_one
        color: $white

        &:hover
            background: $secondary_one-med-light
            border-color: $secondary_one-med-light
            color: $white

        &:focus, &.focus
            outline: none
            background: $secondary-one-med-light
            box-shadow: inset 0 0 0 2px $secondary-one
            color: $white

        &:active, &.active
            background: $secondary_one-light
            border-color: $secondary_one-light
            color: $white

        &.kn-loading
            background: $secondary_one-med-light
            box-shadow: inset 0 0 0 2px $secondary_one
            color: $white

        &.disabled
            background: $gray-light
            border-color: $gray-light 
            color: $gray-darker 

    &.error
        background: $knDanger
        border-color: $knDanger
        color: $white

        &:hover
            background: $knDanger-med-light
            border-color: $knDanger-med-light
            color: $white

        &.focus
            outline: none
            background: $knDanger-med-light
            box-shadow: inset 0 0 0 2px $knDanger
            color: $white

        &:active, &.active
            background: $knDanger-light
            border-color: $knDanger-light
            color: $white

        &.kn-loading
            background: $knDanger-med-light
            box-shadow: inset 0 0 0 2px $knDanger
            color: $white

            i:first-child
                &::before
                    background: white
                &::after
                    background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

        &.disabled
            background: $gray-light
            border-color: $gray-light 
            color: $gray-darker 
    
/*Secondary button class*/
.kn-secondary
    border: 1px solid $primary-one
    background: $primary-one
    color: $white

    &.kn-btn-sm
        font-size: 12px
        padding: $btn-sm-padding
        min-width: $btn-sm

    &:hover
        border: 1px solid $primary-one-med-light
        background: $primary-one-med-light
        color: $white

    &.focus
        outline: none
        box-shadow: inset 0 0 0 2px $primary-one
        background: $primary-one-med-light
        color: $white

    &:active, &.active //pressed
        border: 1px solid $primary-one-light
        background: $primary-one-light
        color: $white

    &.kn-loading
        background: $primary-one-med-light
        box-shadow: inset 0 0 0 2px $primary-one
        color: $white

        i:first-child
            &::before
                background: white
            &::after
                background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

    &.disabled
        background: $gray-light
        border: 1px solid $gray-light 
        color: $gray-darker
        

/*Tertiary button styling*/
.kn-tertiary
    color: $primary-one
    border: 1px solid $primary-one
    background: $white

    &.kn-btn-sm
        font-size: 12px
        padding: $btn-sm-padding
        min-width: $btn-sm

    &:hover
        background: $primary-one-lighter
        border: 1px solid $primary-one
        color: $primary-one

    &.focus
        outline: none
        box-shadow: inset 0 0 0 2px $primary-one
        background: $primary-one-light

    &:active, &.active //pressed
        background: $primary-one-lightest
        border: 1px solid $primary-one
        color: $primary-one

    &.disabled
        color: $gray-darker
        border-color: $gray-darker
        background: $white
    
    &.kn-loading
        background: $primary-one-light
        box-shadow: inset 0 0 0 2px $primary-one
        color: $primary-one

        i:first-child
            &::before
                background: $white
                opacity: .6
            &::after
                background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Light.svg")

    &.warning
        border-color: $secondary_one
        background: $white
        color: $secondary_one

        &:hover
            background: $secondary_one-lighter

        &.focus
            outline: none
            background: $secondary_one-lighter
            box-shadow: inset 0 0 0 2px $secondary_one
            color: $secondary_one

        &:active, &.active
            background: $secondary_one-lightest
            border-color: $secondary_one
            color: $secondary_one
        
        &.disabled
            color: $gray-darker
            border-color: $gray-darker
            background: $white
        
        &.kn-loading
            background: $secondary_one-lighter
            box-shadow: inset 0 0 0 2px $secondary_one
            color: $secondary_one

            i:first-child
                &::before
                    background: $secondary_one
                &::after
                    background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

    &.error
        background: $white
        border-color: $knDanger
        color: $knDanger

        &:hover
            background: $knDanger-lighter
            border-color: $knDanger
            color: $knDanger

        &.focus
            outline: none
            background: $knDanger-lighter
            box-shadow: inset 0 0 0 2px $knDanger
            color: $knDanger

        &:active, &.active //pressed
            background: $knDanger-lightest
            border-color: $knDanger
            color: $knDanger

        &.kn-loading
            background: $knDanger-lighter
            box-shadow: inset 0 0 0 2px $knDanger
            color: $knDanger

            i:first-child
                &::before
                    background: $knDanger
                &::after
                    background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

        &.disabled
            background: $gray-light
            border-color: $gray-light 
            color: $gray-darker 

/*Flat button styling*/
.kn-flat
    color: $primary-one
    background: transparent
    border: 1px solid transparent
    
    &.kn-btn-sm
        padding: $btn-sm-padding
        font-size: 12px
        min-width: $btn-sm

    &:hover
        background: $primary-one-lighter
        color: $primary-one

    &.focus
        outline: none
        background: $primary-one-lighter

    &:active, &.active //press
        background: $primary-one-lightest
        color: $primary-one

    &.kn-loading
        background: $primary-one-lighter
        color: $primary-one

        i:first-child
            &::before
                background: $white
                opacity: .6
                
            &::after
                background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Light.svg")

    &.disabled
        color: $gray-darker
        pointer-events: none
        cursor: not-allowed

.kn-btn-icon
    border-radius: 50%
    min-width: auto
    width: 40px
    height: 40px
    
    i
        font-size: 20px

    &.dropshadow
        box-shadow: 0 1px 2px 3px rgb(0 0 0 / 0.1)

    &.kn-btn-sm
        min-width: $btn-sm
        width: 28px
        height: 28px
        padding: 0

        i
            font-size: 16px

    &.kn-btn-md
        width: 32px
        height: 32px
        padding: 0

        i
            font-size: 20px

/*Button loading spinner*/
.lh-tertiary-btn.spinner
    background-color: $blue
    color: $white

.lh-btn-delete.spinner
    background-color: $knDanger
    color: $white

/*Split button*/
.kn-split-btn
    position: relative
    width: max-content
    
    .kn-btn-group
        display: flex
        .kn-btn:first-child
            border-top-right-radius: 0
            border-bottom-right-radius: 0
            justify-content: left
            min-width: 97px

            &.kn-primary, &.kn-secondary
                border-right: 1px solid rgb(255,255,255, 0.35)

            &.disabled
                border-right: 1px solid $gray

        .kn-btn:last-child
            border-top-left-radius: 0
            border-bottom-left-radius: 0
            min-width: auto
            border-left: none
            padding: 7px 10px

            i
                transition: $kn-transition-150

/*Icon button*/
.kn-icon-btn
    .kn-btn
        &.kn-btn-sm
            min-width: auto
    
        &.kn-flat
            i
                color: $ink-light
            
            &:hover
                i
                    color: $primary_one
                    
        &.disabled
            &.kn-flat
                i
                    color: $gray-darker
        
        &:active, &.active
            &.kn-flat
                i
                    color: $primary-one
            
/*Menu button*/
.kn-menu-btn
    .kn-btn
        justify-content: space-between

        .icon-right
            margin-left: 8px
            

/*Pill button*/
.kn-pill
    border-radius: 16px
    background-color: $gray-light
    color: $ink-light
    border: none
    padding: 5px 10px
    min-width: fit-content
    text-transform: none

    &:hover
        background-color: $gray-lighter


/*Dropdown width based on content*/
.kn-btn-dropdown-content-width
    width: max-content !important