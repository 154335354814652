
.palettes
    max-width: 70%

    .color-palette
        position: relative

        .palette-header
            display: flex

            .title
                text-transform: uppercase
                font-family: 'Avenir-Heavy'
                width: 300px
                padding: 0 17px

        .palette
            .code-block
                font-family: monospace
                padding: 10px
                background: var(--cloud-gray)

                .color-info
                    display: flex
                    width: 100%

                    .color
                        display: flex
                        width: 300px

                        .value
                            margin: 0 10px

    .color-palette:last-child
        margin-bottom: 40px

