
::-webkit-scrollbar
  width: 10px
  background-color: $gray-darker
 
::-webkit-scrollbar-track
  width: 16px
  background-color: #fff

::-webkit-scrollbar-thumb
  padding-left: 3px
  padding-right: 3px
  border-radius: 5px
  width: 10px
  background-color: $gray-darker