//Global Animations    
@keyframes fadeIn 
  0% 
    opacity: 0
  
  100% 
    opacity: 1

//Contextual Menu sub menus
@keyframes showContextSubMenu
  0%
    opacity: 0

  75%
    visibility: visible
    
  100%
    opacity: 1
      