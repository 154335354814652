
.kn-footer
    background: $gray-dark
    position: relative
    bottom: 0
    width: 100%
    height: 50px
    display: flex
    align-items: center
    justify-content: space-between
    z-index: 100

    &.sticky
        position: fixed

    .copyright
        padding-left: 20px
        font-family: 'Avenir-Medium'
        color: $ink

    .right-nav *
        margin: 0 25px

    .right-nav *:last-child
        margin-right: 20px