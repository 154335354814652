
.link
    position: relative
    margin: 0 16px
    font-family: Avenir-medium
    color: $ink-light
    font-size: 16px
    display: inline-block
    padding-bottom: 6px
    cursor: pointer
    
    &:hover
        color: $ink
        text-decoration: none
        
    &.disabled
        cursor: none
        pointer-events: none
        color: grey

.link::before
    content: ""
    position: absolute
    width: 100%
    height: 4px
    bottom: 0
    left: 0
    background-color: $gray-light
    visibility: hidden
    transform: scaleX(0)
    transition: all 0.3s ease-in-out 0s

.link:hover::before
    visibility: visible
    transform: scaleX(1)

.active-link
    color: $ink

    &::before
        content: ""
        position: absolute
        width: 100%
        height: 4px
        bottom: 0
        left: 0
        background-color: $secondary_one
        visibility: visible
        transform: scaleX(1)
        transition: all 0.3s ease-in-out 0s

.kn-link, a
    color: $blue
    cursor: pointer
    text-decoration: none
    
    &:hover
        text-decoration: underline

.kn-quiet-link
    color: $ink
    font-family: 'Avenir-Medium'
    
    &:hover
        text-decoration: underline