
.kn-tooltip-container 
    @include tooltipContainer()
    
    &.top
        @include tooltipArrowDown()

    &.bottom
        margin-top: 12px
        
        &::after
            content: ""
            position: absolute
            top: -13px
            left: 50%
            border-width: 7px
            border-style: solid
            border-color: transparent transparent $ink-light transparent
            transform: translateX(-50%)
    
    &.left
        margin-left: -12px

        &::after
            content: ""
            position: absolute
            top: 50%
            right: -13px
            margin-left: -10px
            border-width: 7px
            border-style: solid
            border-color: transparent transparent transparent $ink-light
            transform: translateY(-50%)

    &.right
        margin-left: 12px

        &::after
            content: ""
            position: absolute
            top: 50%
            left: -3px
            margin-left: -10px
            border-width: 7px
            border-style: solid
            border-color: transparent $ink-light transparent transparent
            transform: translateY(-50%)

@keyframes tooltip-slide 
  0% 
    opacity: 0
    transform: translate(-50%, -30%)
  
  100% 
    opacity: 1
    transform: translate(-50%, 0)
  