/**************/
/*CSS Variables
/**************/ 
:root
    --primary_one: #205D86
    --primary_one-med-light: #4999C9 
    --primary_one-light: #B2D3E7 
    --primary_one-lighter: #C6DEED
    --primary_one-lightest: #E5F0F7
    --primary_one-dark: #375D76
    --primary_one-darker: #1C3C50 

    --secondary_one: #B8551E
    --secondary_one-light: #FBCC94
    --secondary_one-med-light: #E17A00
    --secondary_one-lighter: #FDEAD4
    --secondary_one-lightest: #FEF3E5
    --secondary_one-dark: #9D4B1E
    --secondary_one-darker: #6D3416 

    //Danger colors
    --kn-danger: #A50D08
    --kn-danger-med-light: #DD5A56
    --kn-danger-light: #FA9A88
    --kn-danger-lighter: #FDE4DD
    --kn-danger-lightest: #FDF2EF
    --kn-danger-dark: #632220
    --kn-danger-darker: #330202


    //Black and gray colors
    --white: #ffffff 
    --gray: #D2DBE2
    --gray-light: #E1E6EB
    --gray-lighter: #F0F2F4
    --gray-lightest: #F4F6F7
    --gray-dark: #B9C7D2  
    --gray-darker: #A5B2BD
    --cloud-gray: #E8EAED
    --ink-lightest: #919EA8
    --ink-lighter: #6A757E
    --ink-light: #434C54
    --ink: #1F1D21
    --ink-dark: #030203
    --black: #000000

    //element colors
    --body-bg-color: #f4f4f4
    --border-color: var(--gray-darker)
    --border-radius: 4px
    --header-bg-color: #444444
    --title-color: #4c4c4c
    --title-font-size: 20px
    --value-font-size: 30px
    --body-font-size: 14px
    --value-color: #005b9f
    --sub-title-font-size: 15px
    --font-color: #333333
    --side-menu-bg-color: #dddddd
    --side-menu-icon-size: 30px

    //Chart colors
    --light-blue: #66A8D0
    --purple-lightest: #F6F0FD
    --purple-lighter: #EBDEFF
    --purple-light: #E3D0FF
    --purple: #9C6ADE
    --purple-dark: #50248F
    --purple-darker: #230051
    --indigo-lightest: #F0F2FD
    --indigo-lighter: #E1E4FB
    --indigo-light: #B3BCF5
    --indigo: #5C6AC4
    --indigo-dark: #202E78
    --indigo-darker: #000639
    --blue-lighter: #E5F0F7
    --blue-light: #DAEAF4
    --blue: #246895
    --blue-dark: #396079
    --blue-darker: #1C3C50
    --teal-lightest: #E0F5F5
    --teal-lighter: #CDF2F2
    --teal-light: #B7ECEC
    --teal: #47C1BF
    --teal-dark: #00848E
    --teal-darker: #003135
    --green-lightest: #F3F8E8
    --green-lighter: #EBF4D7
    --green-light: #DDECBC
    --green: #90C221
    --green-dark: #486111
    --green-darker: #2F400B
    --yellow-lightest: #FDF8E5
    --yellow-lighter: #FCF1CD
    --yellow-light: #FFEA8A
    --yellow: #EEC200
    --yellow-dark: #8A6116
    --yellow-darker: #573B00‌

    //global
    --links: $blue
    --font-color: var($ink-light)
    --letter-spacing: 0.8px

    //buttons
    --button-icon-padding-right: 8px

    --primary-btn-font-size: 14px
    --primary-btn-pressed-color: #588CAE 
    --primary-btn-hover-color: #205D86 

    --flat-btn-pressed-color: #E5F0F7
    --flat-btn-hover-color: #C6DEED
    --flat-btn-font-size: 12px
    --flat-btn-sm-font-size: 12px
    --flat-btn-font-size: 14px
    --flat-btn-icon-padding: 7px

    --secondary-btn-hover-color: #66A8D0
    --secondary-btn-pressed-color: #B2D3E7

    --tertiary-btn-pressed-color: #66A8D0
    --tertiary-btn-hover-color: var(--primary-btn-hover-color)

    --btn-icon-size: 16px
    --mainButtonMinWidth: 132px
    --masthead-bg-color: var(--white)
    --btn-sm: 108px
    --btn-sm-padding: 5px 12px

    //elevation
    --masthead-elevation: 11
    --footer-elevation: 10

    //icon
    --pipe-height: 32px

    //container
    --container-column-width: 264px

    //font awesome
    --font-awesome: 'Font Awesome 6 Pro'
    
/*******************/
/*Template vairables
/******************/ 
//Primary colors
$primary_one: var(--primary_one)
$primary_one-med-light: var(--primary_one-med-light)
$primary_one-light: var(--primary_one-light)
$primary_one-lighter: var(--primary_one-lighter)
$primary_one-lightest: var(--primary_one-lightest)
$primary_one-dark: var(--primary_one-dark)
$primary_one-darker: var(--primary_one-darker)

$secondary_one: var(--secondary_one)
$secondary_one-light: var(--secondary_one-light)
$secondary_one-med-light: var(--secondary_one-med-light)
$secondary_one-lighter: var(--secondary_one-lighter)
$secondary_one-lightest: var(--secondary_one-lightest)
$secondary_one-dark: var(--secondary_one-dark)
$secondary_one-darker: var(--secondary_one-darker)

//Danger colors
$knDanger: var(--kn-danger)
$knDanger-med-light: var(--kn-danger-med-light)
$knDanger-light: var(--kn-danger-light)
$knDanger-lighter: var(--kn-danger-lighter)
$knDanger-lightest: var(--kn-danger-lightest)
$knDanger-dark: var(--kn-danger-dark)
$knDanger-darker: var(--kn-danger-darker)


//Black and gray colors
$white: var(--white)
$gray: var(--gray)
$gray-light: var(--gray-light)
$gray-lighter: var(--gray-lighter)
$gray-lightest: var(--gray-lightest)
$gray-dark: var(--gray-dark)
$gray-darker: var(--gray-darker)
$cloud-gray: var(--cloud-gray)
$ink-lightest: var(--ink-lightest)
$ink-lighter: var(--ink-lighter)
$ink-light: var(--ink-light)
$ink: var(--ink)
$ink-dark: var(--ink-dark)
$black: var(--black)

//element colors
$body-bg-color: var(--body-bg-color)
$border-color: var(--border-color)
$border-radius: var(--border-radius)
$header-bg-color: var(--header-bg-color)
$title-color: var(--title-color)
$title-font-size: var(--title-font-size)
$value-font-size: var(--value-font-size)
$body-font-size: var(--body-font-size)
$value-color: var(--value-color)
$sub-title-font-size: var(--sub-title-font-size)
$font-color: var(--font-color)
$side-menu-bg-color: var(--side-menu-bg-color)
$side-menu-icon-size: var(--side-menu-icon-size)

//Chart colors
$light-blue: var(--light-blue)
$purple-lightest: var(--purple-lightest)
$purple-lighter: var(--purple-lighter)
$purple-light: var(--purple-light)
$purple: var(--purple)
$purple-dark: var(--purple-dark)
$purple-darker: var(--purple-darker)
$indigo-lightest: var(--indigo-lightest)
$indigo-lighter: var(--indigo-lighter)
$indigo-light: var(--indigo-light)
$indigo: var(--indigo)
$indigo-dark: var(--indigo-dark)
$indigo-darker: var(--indigo-darker)
$indigo-darker: var(--indigo-darker)
$blue-lighter: var(--blue-lighter)
$blue-light: var(--blue-light)
$blue: var(--blue)
$blue-dark: var(--blue-dark)
$blue-darker: var(--blue-darker)
$teal-lightest: var(--teal-lightest)
$teal-lighter: var(--teal-lighter)
$teal-light: var(--teal-light)
$teal: var(--teal)
$teal-dark: var(--teal-dark)
$teal-darker: var(--teal-darker)
$green-lightest: var(--green-lightest)
$green-lighter: var(--green-lighter)
$green-light: var(--green-light)
$green: var(--green)
$green-dark: var(--green-dark)
$green-darker: var(--green-darker)
$yellow-lightest: var(--yellow-lightest)
$yellow-lighter: var(--yellow-lighter)
$yellow-light: var(--yellow-light)
$yellow: var(--yellow)
$yellow-dark: var(--yellow-dark)
$yellow-darker: var(--yellow-darker)

//global
$links: var(--links)
$font-color: var(--font-color)
$letter-spacing: var(--letter-spacing)

//buttons
$button-icon-padding-right: var(--button-icon-padding-right)

$primary-btn-font-size: var(--primary-btn-font-size)
$primary-btn-pressed-color: var(--primary-btn-pressed-color)
$primary-btn-hover-color: var(--primary-btn-hover-color)

$flat-btn-pressed-color: var(--flat-btn-pressed-color)
$flat-btn-hover-color: var(--flat-btn-hover-color)
$flat-btn-font-size: var(--flat-btn-font-size)
$flat-btn-sm-font-size: var(--flat-btn-sm-font-size)
$flat-btn-font-size: var(--flat-btn-font-size)
$flat-btn-icon-padding: var(--flat-btn-icon-padding)

$secondary-btn-color: var(--secondary-btn-color)
$secondary-btn-hover-color: var(--secondary-btn-hover-color)
$secondary-btn-pressed-color: var(--secondary-btn-pressed-color)

$tertiary-btn-pressed-color: var(--tertiary-btn-pressed-color)
$tertiary-btn-hover-color: var(--tertiary-btn-hover-color)

$btn-icon-size: var(--btn-icon-size)
$mainButtonMinWidth: var(--mainButtonMinWidth)
$masthead-bg-color: var(--masthead-bg-color)
$btn-sm: var(--btn-sm)
$btn-sm-padding: var(--btn-sm-padding)

//elevation
$masthead-elevation: var(--masthead-elevation)
$footer-elevation: var(--footer-elevation)

//icons
$pipe-height: var(--pipe-height)

//container
$container-column-width: var(--container-column-width)

//font awesome
$font-awesome: var(--font-awesome)