
.kn-canvas
    margin-bottom: 100px
    
.kn-page-title
    padding: 35px 20px 20px 20px
    background: var(--white)

    .component-version
        font-size: 20px

.code-comment
    color: lighten(#486111, 10)
    width: 100%
    float: left

.coming-soon
    
#canvasTabs
    .kn-tab-content
        background: transparent

.stackblitz
    iframe
        width: 100%
        height: 1000px

#componentTabs
    #canvasTabs
        .kn-tab-header
            border-bottom: 1px solid var(--border-color)
        
        .kn-tabs
            background: var(--white)
            flex-wrap: wrap

        .kn-tab-content
            overflow-y: auto
            

            .component-accessibility-img
                width: max-content
                max-width: 100%


    .tab-links .tab-content
        background: none

    .links
        border-bottom: 1px solid var(--border-color)

    .angular-code
        position: relative
        width: 85%
        min-height: 125px
        margin: 25px auto
        text-align: left
        font-family: monospace
        margin: 50px auto
        padding: 20px

    .web-code
        position: relative
        width: 85%
        min-height: 300px
        font-family: monospace
        margin: 0 auto
        text-align: left
        padding: 20px

        &.react-index
            min-height: 200px

        .html-code-demo
            margin-left: 35px

            &.react
                margin-left: 60px

    .html-code-demo
        text-align: left

        pre 
            white-space: pre-wrap
        
    .html-tags
        color: var(--primary_one-med-light)
    
    .html-option
        color: var(--primary_one-light)
    
    .html-content
        color: var(--primary_one-light)

    .html-value
        color: var(--secondary_one-med-light)
        
    .code-content
        padding: 0 20px

    .ts-decorator
        color: var(--purple)

    .ts-injector
        color: var(--teal)

    .curly-braces
        color: var(--white)

    .return
        color: var(--knDanger-med-light)

    .comment
        color: lighten(#486111, 10)

    .function
        color: var(--yellow-light)

    .ts-class
        color: lighten(#202E78, 35%)

    .tab-content
        padding: 20px

    .container
        display: flex
        justify-content: space-between

        .code-container
            position: relative
            width: 100%
            border: 1px solid var(--border-color)
            border-radius: var(--border-radius)
            transition: var(--kn-transition-200)
            height: max-content
            background: var(--white)
            // background-image: linear-gradient( 45deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25% ),linear-gradient( 135deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25% ),linear-gradient( 45deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75% ),linear-gradient( 135deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75% )
            // background-size: 20px 20px
            // background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px
            
            .demo-header
                padding: 15px
                display: flex
                align-items: center
                justify-content: flex-end

            .refresh-icon
                margin-right: 10px
            
            .expand-icon
                right: 20px

            .refresh-icon
                right: 60px

            .kn-component
                margin: 0 auto
                padding: 20px
                width: max-content
                min-height: 300px
                //display: flex
                //align-items: center
                transition: var(--kn-transition-250)
                position: relative

                &.expand
                    height: auto
                    min-height: 70vh

                .component-group
                    display: flex    

            .code
                position: relative
                text-align: center
                width: 100%
                color: var(--secondary_one-med-light)
                background: var(--ink-dark)

                .web-download-btn
                    margin: 20px 0

                .code-group
                    position: relative
                    word-break: break-word

                    .code-header
                        width: 100%
                        height: 25px

                    &.script
                        pre
                            display: flex
                            align-items: center
                            justify-content: left
                            text-align: left
                            width: 85%
                            margin: 0 auto
                            padding: 20px
                            overflow-x: auto
                            white-space: pre-wrap
                            word-wrap: break-word
                            min-height: 265px

                            .code-tabs
                                display: none
                            pre
                                display: none

                    .tag
                        position: absolute
                        top: 20px
                        color: var(--white)
                        font-family: 'Avenir-Heavy'

                        &.app-js
                            top: 200px

                        &.index-html
                            top: 0

                    div[web]
                        color: var(--white)
                        margin-left: 62px
                    
                    div[typeScript], div[JavaScript]
                        color: var(--indigo-light)

                div[events]
                    .events
                        min-height: 265px
                        margin-top: 20px

                        .event-item
                            display: flex
                            align-items: baseline
                            position: relative
                            font-family: monospace
                            margin-bottom: 20px
                            
                            i
                                margin-right: 10px
                                color: var(--white)
                                cursor: pointer
                                transition: var(--kn-transition-200)

                            .obj-items
                                position: relative
                                left: 10px
                                display: flex
                                flex-direction: column
                                text-align: left
                                max-height: 0
                                transition: max-height var(--kn-transition-200) ease-in
                                overflow: hidden

                                .obj-item
                                    .label
                                        color: var(--purple)

                                    &.teir-1
                                        &.active
                                            .label
                                                .teir-1-caret
                                                    transform: rotate(90deg)

                            .sub-items-tier-1, .sub-items-tier-2
                                margin-left: 37px
                                text-align: left

                                .index-marker
                                    position: absolute
                                    margin-left: -35px
                                    
                                .obj-item
                                    &.teir-2
                                        &.active
                                            .label
                                                .teir-2-caret
                                                    transform: rotate(90deg)
                            
                            &.active
                                .event-caret
                                    transform: rotate(90deg)

                                .obj-items
                                    max-height: 500px
                                    transition: max-height var(--kn-transition-200) ease-in

                .divider
                    width: 100%
                    border-bottom: 1px solid var(--ink-light)
                
                .copy
                    position: absolute
                    right: 100px
                    top: 20px
                    color: var(--white)
                    cursor: pointer

                #canvasCodeTabs
                    .kn-tab-header
                        background: var(--ink-dark)

                        .kn-tabs
                            background: var(--ink-dark)
                            border-bottom: none

                            .tab-links
                                .link
                                    color: var(--white)
                                
                                &.disabled
                                    .link
                                        color: var(--ink-light)

                    .kn-tab-content
                        background: var(--ink-dark)
                        height: max-content
                
                &.hide
                    animation: hideHTML
                    animation-fill-mode: forwards
                    animation-duration: var(--kn-transition-400)

                &.show
                    animation: showHTML
                    animation-fill-mode: forwards
                    animation-duration: var(--kn-transition-400)

        .actions
            display: flex
            flex-direction: column
            width: 40%
            max-width: 260px
            min-width: 220px
            height: auto
            overflow-y: auto
            background: var(--white)
            padding: 20px
            border: 1px solid var(--border-color)
            border-radius: var(--border-radius)
            margin-right: 20px
            transition: var(--kn-transition-400)

            &.hide
                width: 40%
                animation: hideActions
                animation-fill-mode: forwards
                animation-duration: var(--kn-transition-400)

            &.show
                animation: showActions
                animation-fill-mode: forwards
                animation-duration: var(--kn-transition-400)

            .section-title
                font-family: 'Avenir-heavy'
                text-transform: uppercase

            .action-input
                margin-bottom: 40px

                .input-info
                    display: flex
                    align-items: center
                    justify-content: space-between

                    .info-icon
                        margin: 19px 0 0 5px
            
            .radio-info, .toggle-info
                display: flex
                align-items: center
                
                i
                    margin-left: 6px

            .divider
                border-top: 1px solid var(--border-color)
                margin-bottom: 20px

            .references
                opacity: 0
                position: absolute
                z-index: -10

                &.show
                    z-index: 1
                    animation: fadeIn 1s
                    animation-fill-mode: forwards

            .item-row
                padding: 15px 0

                .link-group
                    display: flex
                    flex-direction: column

                    .kn-link
                        margin: 5px 0

                &.info-section
                    display: flex
                    align-items: center

                    .info-icon
                        margin-left: 10px
                        
                .kn-input-contain
                    margin: 20px 0


.canvas-inner-container
    margin: 20px 0
    padding: 20px
    background: var(--white)
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius)
    
    .changelog-header, .entry
        display: flex
        width: 100%

        .item
            width: 280px
            padding: 0 30px

    .changelog-header
        .item
            font-family: 'Avenir-heavy'
        .item:last-child
            width: 100%

    .entry:last-child
        border-bottom: none

    .entry
        border-bottom: 1px solid var(--border-color)
        padding: 10px 0
        align-items: center

        .item:last-child
            width: 100%

            ul
                padding: 0 15px

    .prop
        .item
            font-family: monospace

        .item:first-child
            font-style: italic
            color: var(--purple-dark)
            font-weight: bold

        .item:nth-child(3)
            color: var(--blue)

        .item:last-child
            width: 50%
   
    pre
        padding: 15px
        background: var(--ink-dark)
        border-radius: 4px
        overflow: auto

.section-container
    padding: 0 20px
    width: 80%
    max-width: 824px

    .section-title
         font-size: 24px
         font-weight: bold
         margin: 20px 0

    section
        margin: 30px 0

        .codesnippet
            display: flex
            align-items: baseline

            pre
                padding: 0 0 0 10px
                margin: 0

        .label
            font-weight: bold
        code
            background: aliceblue
            border: 1px solid var(--border-color)
            padding: 1px 2px
            border-radius: 3px

    // img
    //     max-width: 800px

    .code
        border: 1px solid var(--gray-darker)
        border-radius: var(--border-radius)
        padding: 15px
        font-family: monospace
        background: var(--gray-lighter) 

        code
            background: none
            border: none    
        
@keyframes hideActions
    0%
        opacity: 1
        width: 40%
        min-width: 220px
        
    100%
        opacity: 0
        width: 0
        min-width: 0
        padding: 0
        margin: 0
        border: 0

@keyframes showActions
    0%
        width: 0
        min-width: 0
        opacity: 0

    100%
        opacity: 1
        width: 40%
        min-width: 220px

@keyframes hideHTML
    0%
        opacity: 1
    
    50%
        opacity: .5
        
    100%
        height: 0
        opacity: 0
        overflow: hidden
        
@keyframes showHTML
    0%
        opacity: 0
    
    50%
        opacity: 0
        
    100%
        opacity: 1

@keyframes fadeIn
    0%
        opacity: 0
    
    50%
        opacity: .5
        
    100%
        opacity: 1
        