.login-page
    background: url('../../assets/images/logo.png')
    width: 100vw
    height: 100vh
    background-repeat: no-repeat
    margin: 0
    padding: 0
    background-attachment: fixed
    background-size: cover
    overflow: hidden

    .title
        font-family: 'Avenir-Heavy'
        font-size: 30px

    .login-container
        width: 350px
        margin: 0 auto
        background-color: white
        border-radius: var(--border-radius)
        position: fixed
        top: 45%
        left: 50%
        transform: translateX(-50%)

        .login-btn
            display: flex
            align-items: center
            justify-content: space-between
            flex-direction: row-reverse
            width: 100%

            .login-error
                color: var(--kn-danger)
                margin-right: 20px
