
/*Radio button styling*/
.kn-radio-container
    display: flex
    font-family: "Avenir-Book"
    font-size: 14px
    box-sizing: center-box

    &.error
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before 
            border: 2px solid $knDanger

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after 
            background: $knDanger

    &.warning
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before 
            border: 2px solid $secondary_one

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after 
            background: $secondary_one

    &.disabled
        pointer-events: none

        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before 
            border: 2px solid $gray-dark

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after 
            background: $gray-dark

        .label-text
            color: $gray-dark

    label
        text-transform: capitalize
        font-size: 14px

        //this is used so the label text is not clickable. I kept it out of the theme library because this seems like unique behavior. 
        .text
            pointer-events: none
            position: absolute
            width: max-content

    .label-text
        margin-left: 32px
        color: $ink

    [type="radio"]:checked,
    [type="radio"]:not(:checked) 
        position: absolute
        left: -9999px
        display: none

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
        position: relative
        cursor: pointer
        line-height: 20px
        display: inline-block
        color: $ink

    //hover 
    [type="radio"]:checked + label, [type="radio"]:not(checked) + label
        &:hover
            &:before
                box-shadow: 0 0 0 7px $primary-one_lighter

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before 
        content: ''
        position: absolute
        left: 0
        top: 2px
        width: 16px
        height: 16px
        border-radius: 100%
        background: #fff
        box-sizing: unset

    //stroke
    [type="radio"]:not(:checked) + label:before
        border: 2px solid $ink-lighter

    [type="radio"]:checked + label:before
        border: 2px solid $primary-one
    
    [type="radio"]:checked + label
        color: red

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after 
        content: ''
        width: 10px
        height: 10px
        background: $primary_one
        position: absolute
        top: 7px
        left: 5px
        border-radius: 100%
        -webkit-transition: all 0.2s ease
        transition: all 0.2s ease

    [type="radio"]:not(:checked) + label:after 
        opacity: 0
        -webkit-transform: scale(0)
        transform: scale(0)

    [type="radio"]:checked + label:after 
        opacity: 1
        -webkit-transform: scale(1)
        transform: scale(1)

        
