
.kn-spreadsheet
    font-family: 'Avenir-book'
    background: var(--gray-lighter)
    padding: 20px
    border-radius: var(--border-radius)
    width: 100%

    .kn-spreadsheet-header
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        margin: 0 0 20px 0
        color: var(--ink)

        .kn-spreadsheet-row-count
            display: flex

            .kn-label 
                margin-right: 4px

            .value
                font-family: 'Avenir-Heavy'


        .kn-legend
            order: 1
            align-self: flex-end
            display: flex
            align-items: center
            
            .swatch-container:last-child
                margin: 0 0 0 12px

            .swatch-container
                display: flex
                align-items: center
                margin: 0 12px

                .swatch
                    width: 12px
                    height: 12px
                    margin-right: 10px
                    border-radius: 2px

                .text
                    .validation-count
                        font-family: 'Avenir-Heavy'
                
                &.error
                    .swatch
                        border: 1px solid var(--kn-danger)
                        background: var(--kn-danger-light)
                
                &.duplicate
                    .swatch
                        border: 1px solid var(--secondary_one)
                        background: var(--secondary_one-light)
                
                &.invalid
                    .swatch
                        border: 1px solid var(--yellow-dark)
                        background: var(--yellow-light)

                &.validation-4
                    .swatch
                        border: 1px solid var(--indigo)
                        background: var(--indigo-light)

                &.validation-5
                    .swatch
                        border: 1px solid var(--purple)
                        background: var(--purple-light)

.e-spreadsheet .e-active-cell
    border: 2px solid var(--primary_one-med-light)

.e-spreadsheet .e-autofill
    background-color: var(--primary_one-med-light)

.e-spreadsheet .e-selection
    border-color: var(--primary_one-med-light)
    background-color: rgba(66, 178, 249, 0.16)//primary lighter
    
.e-tab .e-tab-header .e-indicator
    background: var(--primary_one-med-light)

.e-spreadsheet .e-sheet-panel:not(.e-rtl) .e-selectall.e-highlight
    border-right-color: var(--primary_one-med-light)

.e-spreadsheet .e-selectall.e-highlight
    border-bottom-color: var(--primary_one-med-light)

.e-contextmenu-wrapper ul .e-menu-item.e-disabled, .e-contextmenu-container ul .e-menu-item.e-disabled
    color: var(--gray-darker)

.e-dropdown-popup ul .e-item:hover
    @include dropdownHover()
        
//copy gif border
.e-spreadsheet .e-copy-indicator div.e-top, .e-spreadsheet .e-range-indicator div.e-top, .e-spreadsheet .e-formularef-indicator div.e-top,
.e-spreadsheet .e-copy-indicator div.e-bottom, .e-spreadsheet .e-range-indicator div.e-bottom, .e-spreadsheet .e-formularef-indicator div.e-bottom,
.e-spreadsheet .e-copy-indicator div.e-left, .e-spreadsheet .e-range-indicator div.e-left, .e-spreadsheet .e-formularef-indicator div.e-left,
.e-spreadsheet .e-copy-indicator div.e-right, .e-spreadsheet .e-range-indicator div.e-right, .e-spreadsheet .e-formularef-indicator div.e-right
    background: url(../assets/images/kn-spreadsheet_copy.gif)

.e-spreadsheet .e-colresize-handler, .e-spreadsheet .e-rowresize-handler
    border-color: var(--primary_one-med-light)

.e-yellowft
    background-color: var(--secondary_one-light) !important
    color: #000 !important
    font-weight: bold !important