
.sitename
    color: var(--white)

    .version
        font-size: 14px
        font-family: 'Avenir-Book'
        margin: 5px

.company-logo
    background: url(../../assets/images/kraken_header_logo.png)
    width: 42px
    height: 39px
    background-size: 75px
    border-radius: 50%
    background-position: -18px