.legend
    position: absolute
    width: max-content
    right: 145px
    font-family: 'Avenir-Book'
    color: var(--ink)

    .legend-item
        border-left: 4px solid transparent
        padding-left: 8px
        margin: 5px 0

        &:hover
            cursor: pointer
            font-weight: 900

    .legend-active-link 
        border-left: 4px solid var(--secondary_one)
        padding-left: 8px
        font-weight: 900
       