
.canvas-container #componentTabs .container .code-container
    &#kraken-panel
        .kn-component
            width: 100%
            height: 500px
            display: flex
            justify-content: center
            align-items: center
            background: var(--gray-lighter)
            border-top: 1px solid var(--border-color)

            .panel-container
                width: 600px
                height: 400px
                overflow-y: hidden
                border: 1px solid var(--border-color)
                border-radius: 4px

                &.highlight
                    border-color: var(--green)
                    box-shadow: 0 0 2px 1px var(--green)

                    .example-page
                        .inner-container
                            border-color: var(--primary_one-med-light)
                            box-shadow: 0 0 2px 1px var(--primary_one-med-light)

                
                .example-page
                    .inner-container
                        margin: 10px
                        padding: 15px
                        background: white
                        border: 1px solid var(--border-color)
                        border-radius: 4px
                        
                
            .kn-panel
                .panel-content
                    display: flex
                    flex-direction: column

                    &.row
                        flex-direction: row

                        .panel-col:first-child
                            width: 50%

                    .text
                        margin: 10px 0
                        display: flex
                        align-items: center

                        i
                            margin-right: 10px

            
.item-row
    .legend
        transition: 1s
        opacity: 0

        .swatch-container
            display: flex
            align-items: center
            margin: 20px 0
            
            .swatch
                height: 20px
                width: 20px
                min-width: 20px
                border-radius: 4px
                margin-right: 10px
                background: var(--green)
        
            .label
                color: var(--ink)
                font-family: monospace
                background: var(--gray-lighter)
                padding: 5px
                border: 1px solid var(--gray-darker)
                border-radius: var(--border-radius)

        .swatch-container:last-child
            .swatch
                background: var(--primary_one-med-light)

        &.show
            opacity: 1