.kn-autocomplete

    .inner
        .clear-icon
            display: block
            
    .kn-dropdown-item
        position: relative
        height: 41px
        overflow: hidden


        .target, .item
            width: 100%
            height: 100%
            position: absolute
            
        .target
            z-index: 10

        .item
            z-index: 0
        
        &:hover
            .item
                .kn-bold
                    text-shadow: none
