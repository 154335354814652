

//transitions
:root
    --kn-transition-100: .1s
    --kn-transition-150: .15s
    --kn-transition-200: .2s
    --kn-transition-250: .25s
    --kn-transition-300: .3s
    --kn-transition-400: .4s
    --kn-transition-500: .5s
    --kn-transition-5s: 5s
    --kn-transition-7s: 7s


$kn-transition-100: var(--kn-transition-100)
$kn-transition-150: var(--kn-transition-150)
$kn-transition-200: var(--kn-transition-200)
$kn-transition-250: var(--kn-transition-250)
$kn-transition-300: var(--kn-transition-300)
$kn-transition-400: var(--kn-transition-400)
$kn-transition-500: var(--kn-transition-500)
$kn-transition-5s: var(--kn-transition-5s)
$kn-transition-7s: var(--kn-transition-7s)

