
.kn-checkbox
    &.disabled
        .kn-checkbox-inner
            input[type="checkbox"] 
                background-color: $gray-lighter
                border-radius: 0.15em
                pointer-events: none

            input[type="checkbox"]::before
                background-color: $gray-dark
                border-radius: 0.15em
                
            input[type="checkbox"]
                border-color: $gray-darker
            
            input[type="checkbox"]:not(checked)
                background-color: $gray-lighter
                border-radius: 0.15em

    .kn-checkbox-label
        display: flex
        align-items: baseline
        pointer-events: none

        .checkbox-label
            margin: 0 15px
            display: inline-block
            font-size: 14px
            color: $ink
            font-family: 'Avenir-book'

    .kn-checkbox-inner
        padding: 1px

        &:hover > input[type="checkbox"]:checked
            box-shadow: 0 0 0 7px $primary-one_lighter
            border-radius: 50%
            background: $primary-one_lighter

        &:hover:has(> input[type="checkbox"]:not(checked))
            box-shadow: 0 0 0 7px $primary_one-lighter
            border-radius: 50%
            background: $primary_one-lighter

        input[type="checkbox"] 
            -webkit-appearance: none
            appearance: none
            background-color: #fff
            margin: 0
            font: inherit
            color: currentColor
            width: 20px
            height: 20px
            border: 1px solid $ink-lighter
            border-radius: 0.15em
            transform: translateY(-0.075em)
            display: grid
            place-content: center
            cursor: pointer
            top: 1px
            position: relative
            pointer-events: all

            &.input-error
                border: 1px solid $knDanger

            &.input-warning
                border: 1px solid $secondary_one

    input[type="checkbox"]::before
        content: "\f00c"
        font-family: 'Font Awesome 6 Pro'
        color: $white
        transform: scale(0)
        box-shadow: inset 1em 1em var(--form-control-color)
        background-color: $primary_one
        border-radius: $border-radius
        width: inherit
        height: inherit
        display: flex
        align-items: center
        justify-content: center

    input[type="checkbox"]:checked::before 
        transform: scale(1)