
/*Optional outer container for the grid
  grid action buttons go inside the top of the container*/
.kn-rim
    width: 100%
 
.kn-grid
    position: relative
    padding: 0 15px 15px 15px
    background: $white
    border-radius: 4px
    border: 1px solid $border-color

    .kn-grid-nav
        width: 100%
        text-align: right
        
        .inner
            display: flex
            justify-content: flex-end
            align-items: center

            .kn-pipe
                border-left: 1px solid $gray
                height: 20px
                position: relative
                margin: 0 8px

            .item
                margin: 8px
                width: auto

            .item:last-child
                margin-right: 0

.e-grid
    border:0
    border-radius: 4px
    border-width: 0 !important
    box-shadow: 0 5px 7px 1px #ccc
    box-shadow: none
    font-family: "Avenir-Book"

    ::-webkit-scrollbar
        width: 10px
        height: 10px
        background-color: $gray-darker
    
    ::-webkit-scrollbar-track
        width: 16px
        height: 16px
        background-color: #fff

    ::-webkit-scrollbar-thumb
        padding-left: 3px
        padding-right: 3px
        border-radius: 5px
        width: 10px
        background-color: $gray-darker

    td
        &.e-active
            background: $white

            &:hover
                background-color: $primary_one-lightest
                border-top: 1px solid $primary_one-med-light 
                border-bottom: 1px solid $primary_one-med-light

    &.kn-compressed-grid
        .e-row

            .e-rowcell
                padding: 0 21px
                height: 34px

                .kn-grouped-column
                    .grouped-columns
                        .column
                            line-height: 34px

    /*Set grid font type*/
    .e-control,
    .e-wrapper
        font-family: "Avenir-medium"

    //frozen column custom bottom scrollbar styling 
    .e-scrollbar
        z-index: 1000
        width: 100%
        border-left: 1px solid $border-color
        bottom: 50px

        .e-movablescrollbar
            max-height: 17px !important

    //grouped header
    &.kn-grouped-header
        .e-templatecell
            padding: 0
            position: relative

        .e-gridheader
            .e-headercell

                .kn-grouped-column
                    .grouped-header-title
                        border-bottom: 1px solid #7E97A7
                        text-align: center
                        line-height: 24px

                    .grouped-columns
                        .column:first-child
                            border-right: 1px solid #7E97A7

                        .column
                            font-family: Avenir-Heavy
                            line-height: 24px
                            text-align: left
                            padding: 0 20px

        .kn-grouped-column
            position: absolute
            text-transform: uppercase
            font-family: 'Avenir-Heavy'
            width: 100%
            top: 0

            .grouped-columns
                width: 100%
                list-style: none
                display: flex
                padding: 0
                margin: 0

                .column
                    padding: 2px
                    width: 50%
                    text-align: center
                    font-family: Avenir-Book
                    line-height: 48px

    //grid header
    .e-gridheader
        padding-right: 0 !important
        border: 0
        border-top-width: 0 !important
        
        .e-detailheadercell
            border-right: none !important

        .e-fltr-icon .e-sortfilterdiv
            margin: -15px 35px

        tr:first-child th
            border-right: 1px solid #7E97A7

        tr th:last-child
            border-right: none
            
            &.e-rightfreeze
                background-color: var(--primary_one-dark) !important
                //border-left: 1px solid #7E97A7

        .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow)
            color: $white
            font-size: 14px
            position: relative

        .e-headercontent, .e-detailheadercell
            border-top-left-radius: 5px
            border-top-right-radius: 5px
            background-color: $primary-one-dark
            padding-right: 10px

            .e-headertext
                color: #fff
                text-transform: uppercase
                font-size: 14px
                font-family: 'Avenir-Heavy'

        .e-headercell[aria-sort=ascending] .e-headertext, .e-headercell[aria-sort=descending] .e-headertext
            color: $white
        
        .e-headercell, .e-detailheadercell
            height: 49px

            .e-checkbox-wrapper
                .e-check
                    background-color: $white !important
                    color: $primary_one

            .kn-grid-header-multi-line
                display: flex
                flex-direction: column
                justify-content: center
                line-height: 15px
                text-transform: uppercase
                font-family: 'Avenir-Heavy'

            .e-checkbox-wrapper
                .e-frame, .e-frame
                    border-color: $border-color
                    background-color: $primary-one-dark
                
                .e-stop
                    background-color: $white
                    color: $primary_one

            .e-rhandler, .e-rsuppress
                border-right: none

            .e-icon-ascending:hover::before,
            .e-icon-descending:hover::before,
            .e-icon-descending:hover::before
                background: $blue-darker

            //filter / sort icons
            .e-ascending, .e-descending
                height: auto
                width: auto
                left: 10px
                top: -7px

                &:hover
                    background: $blue-darker

            .e-filtermenudiv
                left: 4px
                top: -4px

                &:hover
                    background: $blue-darker

            .e-filtermenudiv, .e-sortfilterdiv
                padding: 6px 7px
                border-radius: 50%
                width: 28px
                height: 28px

            .e-icon-filter::before, .e-grid-menu .e-icon-filter::before
                font-weight: 400
                content: '\f0b0'
                font: 16px
                font-family: 'Font Awesome 5 pro'

            .e-filtered::before, .e-grid-menu .e-filtered::before
                font-weight: 900
                content: '\f0b0'
                font: 16px
                font-family: 'Font Awesome 5 pro'
                color: $white

    //filter checkbox
    .e-checkfltrnmdiv //filter dialog: no matches found
        font-size: 12px
        color: $ink

    .e-checkbox-wrapper, .e-css.e-checkbox-wrapper
        .e-label
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            font-family: 'Avenir-Book'
            font-size: 14px
            margin-left: 20px
            color: $ink

        .e-check::before
            font-size: 14px
            top: 2px
            position: relative
            padding: 2px
        
        .e-frame
            height: 20px
            width: 20px
            border-width: 1px
            border-radius: 3px

        .e-frame::before
            border-radius: 3px

        .e-stop
            font-family: "Font Awesome 6 Pro"
            font-weight: 900
            background: $primary_one
            color:  $white

            &:hover
                color:  $white
        
        .e-stop::before
            content: "\f068" 
            position: relative
            top: 3px
            font-size: 13px

    .e-ftrchk.e-chkfocus
        background-color: #fff

    .e-content, .e-table, .e-headercell
        background-color: transparent !important

    .e-detailcell .e-gridheader, .e-detailcell .e-gridcontent
        background: transparent 

    .e-detailcell  
        border-top: 0
        border-bottom: 1px solid $cloud-gray 

        .e-gridcontent
            width: fit-content
            
        .e-row
            &:hover
                .e-rowcell
                    background-color: $primary_one-lightest
                    border-top: 1px solid $primary_one-med-light !important
                    border-bottom: 1px solid $primary_one-med-light !important
                

    .e-detailrow
        background: #f4f4f4

        .e-detailcell, .e-detailindentcell
            padding: 0

        .e-columnheader
            display: none

        .e-content
            border-left: 0
            border-right: 0

        .e-emptyrow
            td
                padding-left: 20px

        .e-detailindentcell
            border: 0

    .e-control
        border: none
        font-size: 14px
        font-family: 'Avenir-book'

    .e-css
        font-size: 14px
        font-family: 'Avenir-book'

    
    .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check
        background-color: $primary-one-dark

    //nested row 
    .e-detailrowcollapse
        border-top: 0
        border-bottom: 1px solid $cloud-gray

    .e-detailrowexpand
        border-top: 0
        border-bottom: 1px solid $cloud-gray


    .e-detailrowcollapse, .e-detailrowexpand
        .e-icon-grightarrow, .e-icon-gdownarrow
            font-family: 'Font Awesome 6 Pro'
            font-weight: 400
            font-size: 16px
            color: $ink

        .e-icon-gdownarrow::before
            content: '\f078'

        .e-icon-grightarrow::before
            content: '\f054'

    .e-detailrow
        background: $white
        color: $ink
        font-size: 14px

    .e-row:first-of-type
        &:hover
            .e-detailrowcollapse, .e-detailrowexpand
                border-top: 0

    //remove top border for the first row after a new row
    .e-row.row-added + .e-row
        &:hover
            .e-rowcell, .e-detailrowcollapse
                border-top: 1px solid $white !important

    .e-row
        cursor: pointer
        white-space: nowrap
        overflow: hidden

        &:hover
            .e-rowcell
                .details-panel-btn
                    opacity: 1
                    
            .e-active
                background-color: $primary_one-lightest
                border-top: 1px solid $primary_one-med-light 
                border-bottom: 1px solid $primary_one-med-light

            .e-rowcell, .e-detailindentcell
                background-color: $primary_one-lightest
                border-top: 1px solid $primary_one-med-light !important
                border-bottom: 1px solid $primary_one-med-light !important

                &.e-active
                    background: $primary_one-lightest

        .disablecheckbox
            pointer-events: none

            .e-checkbox-wrapper

                .e-frame
                    background-color: $gray-lighter
                    border-color: $gray-darker

        .e-focused:not(.e-menu-item):not(.e-editedbatchcell)
            box-shadow: none

        .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd)
            color: $ink

        .e-rowcell.e-focused
            border: none 
            
        .e-rowcell
            font-size: 14px 
            border-top: 1px solid $white 
            border-bottom: 1px solid $cloud-gray
            color: $ink 
            padding: 3px 21px
            height: 48px

            .details-panel-btn
                opacity: 0
                transition: $kn-transition-200
                display: flex
                align-items: center
                justify-content: center
            
            &.e-active
                background: $white
  
        .e-emptyrow
            font-size: 14px
            text-align: center

        &:hover:first-child
            .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell)
                border-top: none


    //new row added to the grid
    .row-added
        .e-rowcell, .e-detailrowcollapse, .e-detailrowexpand, .e-selectionbackground
            background-color: $green-lighter 
            border-bottom-color: $green
        
        .e-rowcell.e-active, .e-responsive .e-rowcell.e-active
            background: $green-lighter

        &:hover
            .e-rowcell, .e-detailrowcollapse, .e-detailrowexpand
                background-color: $green-lighter !important
                border-bottom-color: $green !important
                border-top: none !important

    .e-detailrow
        .e-headercontent, .e-detailheadercell
            background-color: #f4f4f4 

            .e-headertext
                color: #000
                opacity: 0.6

    //frozen column 
    .e-frozenheader.e-frozen-right-header > .e-table, 
    .e-frozencontent.e-frozen-right-content > .e-table
        border: 0

    .e-frozenscrollbar
        border-top: 0
        border-right: 1px solid $border-color

    .e-content
        border-left: 1px solid $border-color
        border-right: 1px solid $border-color

    .e-input-focus
        border-bottom: blue !important

    .e-input::selection
        @include inputSelectedText

    //column chooser dialog
    .e-ccdlg
        left: unset !important
        top: -1px
        right: 6px
        height: 400px

    //filter dialogs
    .e-dialog
        border: 1px solid $gray-darker
        border-radius: 4px
        width: 300px !important //this overrides the dynamic width that syncfusion applies
        box-shadow: $box-shadow-elv-2

        ::-webkit-scrollbar
            width: 8px
    
        ::-webkit-scrollbar-thumb
            border: 1px solid #fff
        
        .e-contextmenu-wrapper
            ul
                color: var(--ink)

                .e-separator
                    margin-top: 6px
                    border-top: 1px solid var(--border-color)

                li
                    display: flex
                    align-items: baseline

                    .e-excl-filter-icon
                        &::before
                            content: "\f0b0"
                            font-family: "Font Awesome 6 pro"
                    .e-filtered
                        &::before
                            content: "\f0b0"
                            font-family: "Font Awesome 6 pro"
                            font-weight: 900
                            color: var(--ink-light)
                    &.e-disabled
                        color: var(--gray-darker)

                        .e-menu-icon
                            color: var(--gray-darker)

        .e-dlg-header-content
            display: none

            .e-dlg-header
                display: none

        .e-dlg-content
            padding: 0 
            overflow-x: hidden
            margin: 0
            
            .e-main-div
                margin-right: 4px
                float: left
                width: 100%

                .e-cc-searchdiv
                    display: none
                    position: relative
                    top: 0
                    left: 0
                    width: 256px

                    .e-input
                        padding: 0 10px

                    .e-ccsearch-icon::before
                        color: $ink-light !important
                        font-size: 17px !important
                        opacity: 1 !important

                .e-cc-contentdiv
                    height: auto
                    max-height: 500px

                    .e-cclist
                        border-top: 1px solid #fff
                        border-bottom: 1px solid #fff
                        padding: 15px 20px
                        cursor: pointer

                        &:hover
                            @include dropdownHover

                            .e-ccheck
                                .e-checkbox-wrapper
                                    .e-label
                                        font-weight: 900



            //search box within filter dialog
            .e-searchcontainer
                .e-input-group
                    border:1px solid $gray-darker !important
                    border-radius: 4px !important
                    margin: 20px !important
                    width: 256px

                    .e-searchinput
                        color: $ink
                        font-size: 14px
                        font-family: 'Avenir-book'
                        line-height: 1.9
                        display: block
                        padding: 8px 37px 8px 10px
                        font-weight: 400
                        line-height: 1.5
                        background-color: #fff
                        background-clip: padding-box
                        border-radius: 0.25rem
                        width: 100%

                    .e-search-icon
                        color: $ink-light
                        font-size: 17px
                        padding: 5px

                    .e-input-focus
                        display: none
                        ::-webkit-scrollbar
                            width: 6px

                .e-searchbox
                    border-bottom: 1px solid $gray-darker
                    padding: 0 !important

                .e-spinner
                    margin-left: 0

                    .e-checkboxlist
                        margin-right: 4px
                        margin-top: 0

                        .e-ftrchk
                            cursor: pointer
                            border-top: 1px solid #fff
                            border-bottom: 1px solid #fff

                            &:hover
                                @include dropdownHover

                                .e-checkbox-wrapper
                                    .e-label
                                        font-weight: 900


    

        .e-footer-content
            @include syncFusionDialogFooter(1px solid $gray-darker)

    &:active, &:focus
        color: $primary_one-dark
        background-color: transparent
    &:hover
        color: $primary_one-dark
        background-color: transparent
    .e-filtered:before
        color: $white

    .e-dropdownlist
        .e-input-focus
            border: 2px solid $primary_one !important
        .e-pager
            border-bottom-left-radius: 5px
            border-bottom-right-radius: 5px
        
    //paging
    .e-pager
        border-left: 1px solid $border-color
        border-right: 1px solid $border-color
        border-bottom: 1px solid $border-color
        border-bottom-right-radius: 4px
        border-bottom-left-radius: 4px
        color: $ink

        .e-pagerconstant
            margin: 0 0 6px 10px
    
    .e-pager .e-currentitem, .e-pager .e-currentitem:hover
        background-color: $primary-one
        border-radius: 50%
    
    .e-pager .e-spacing.e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover
        background: $primary-one-lighter
        border-radius: 50%

    .e-pager .e-spacing, .e-pager .e-numericitem:hover, .e-pager .e-currentitem, .e-pager .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active)
        padding: 8px 11px

    .e-checkboxfilter .e-ftrchk, .e-checkboxfilter .e-searchbox
        padding: 14px 20px
        
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, 
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, 
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, 
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, 
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, 
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, 
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, 
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after
        background: transparent 

    .e-detailrowcollapse:not(.e-editedbatchcell), 
    .e-detailrowexpand:not(.e-editedbatchcell), 
    .e-emptyrow:not(.e-editedbatchcell), 
    .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell), 
    .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell), 
    .e-rowcell:not(.e-editedbatchcell)
        opacity: 1

    .e-focused:not(.e-menu-item)
        box-shadow: none

    .e-input::selection
        @include inputSelectedText

    //grid pagination
    .e-gridpager
        background: $gray-light

        .e-pagercontainer
            background-color: transparent
            margin: 0 20px

            .e-icons
                background: transparent 
                color: $ink-light

                &:hover
                    background: $primary-one-lighter
                    color: $primary_one
                    border-radius: 50%

                &.e-disable
                    color: $gray-darker

                &::before
                    font-size: 16px

            .e-numericcontainer
                position: relative
                top: -2px

                .e-active
                    background: $primary_one
                    color: #fff

            .e-numericitem 
                background: transparent
                color:  $primary_one-dark
                font-family: 'Avenir-Heavy'
                border-right-style: unset
                border-right-width: unset
                min-width: unset
                margin: 0 5px

            .e-np
                position: relative
                top: -4px
                color: $ink-light

                &:hover
                     background: unset

        .e-pagesizes
            .e-pagerdropdown
                border: 1px solid $gray-darker
                border-radius: 4px
                background: #fff
                position: relative
                top: -4px
                color: $ink
                font-family: 'Avenir-Book'
                width: 80px
                height: 40px
                
                .e-control-wrapper
                    vertical-align: unset

                .e-input-group
                    border: 0
                    padding: 0px 10px

                .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before
                    content: '\f078'
                    font: 16px 
                    font-family: 'Font Awesome 5 pro'
                    color: $ink-light
        
        input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper
            font-family: 'Avenir-Book'

//popup menu
.e-dropdownbase 
    .e-list-parent
        .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover
            color: $primary_one
            background: $white

.e-dropdownbase 
    .e-list-parent
        .e-list-item
            border-top: 1px solid $white 
            border-bottom: 1px solid $white 
            height: 40px

            &:hover
                cursor: pointer
                border-top: 1px solid $primary_one-med-light
                border-bottom: 1px solid $primary_one-med-light 
                background: $blue-lighter 
                font-family: 'Avenir-Heavy'
                outline: none

//grid overrides
.e-grid.e-gridhover tr[role=row]:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:not(.e-disable-gridhover):not(.e-detailrow):hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:not(.e-disable-gridhover):hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover tr[role=row]:not(.e-disable-gridhover):hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:not(.e-disable-gridhover):not(.e-detailrow):hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell)
    background-color: $primary_one-lightest
    color: $ink

.e-grid.e-responsive .e-rowcell, .e-grid.e-responsive .e-headercelldiv
    color: $white
    font-size: 14px

    .e-headertext
        padding: 0 5px

.e-grid .e-dialog.e-checkboxfilter, .e-grid .e-dialog.e-flmenu
    min-width: 300px

.e-input-group .e-input-group-icon:last-child, .e-input-group.e-bigger .e-input-group-icon:last-child, .e-input-group .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon:last-child, .e-input-group.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-bigger .e-input-group.e-small .e-input-group-icon:last-child
    margin-right: 10px

.e-grid .e-filtered::before
    color: $white

.e-grid.e-gridhover .e-row tr:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover .e-row:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover .e-row:not(.e-disable-gridhover):not(.e-detailrow):hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover .e-row:not(.e-disable-gridhover):not(.e-detailrow):hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover .e-row:not(.e-disable-gridhover):not(.e-detailrow):hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover .e-row:not(.e-disable-gridhover):not(.e-detailrow):hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell)
    background-color: $primary_one-lightest
    border-top: 1px solid $primary_one-med-light 
    border-bottom: 1px solid $primary_one-med-light

.e-pager .e-pagercontainer .e-firstpage, .e-pager .e-pagercontainer .e-prevpage, .e-pager .e-pagercontainer .e-firstpagedisabled, .e-pager .e-pagercontainer .e-prevpagedisabled, .e-pager .e-pagercontainer .e-nextpage, .e-pager .e-pagercontainer .e-lastpage, .e-pager .e-pagercontainer .e-nextpagedisabled, .e-pager .e-pagercontainer .e-lastpagedisabled
    padding: 8px
    margin-top: 7px

.e-grid.e-default .e-detailrow .e-lastrowcell
    border-bottom: 0

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv
    padding: 0 1.8em 0 3px

.e-grid .e-gridheader tr th:first-child
    padding-left: 26px

.e-checkbox-wrapper:hover .e-frame.e-stop, .e-css.e-checkbox-wrapper:hover .e-frame.e-stop
    color: $white

//column filter (excel)
.e-xlflmenu
    border-top-left-radius: var(--border-radius)
    border-top-right-radius: var(--border-radius)
    border-bottom-left-radius: var(--border-radius)
    border-bottom-right-radius: var(--border-radius)
    width: 656px !important

    .e-dlg-header-content
        background: var(--primary_one-dark)
        padding: 15px
        border-top-left-radius: var(--border-radius)
        border-top-right-radius: var(--border-radius)
        
        .e-dlg-closeicon-btn
            .e-icon-dlg-close
                color: #fff
            
            &:hover
                transition: $kn-transition-200
                background-color: var(--primary_one-darker)

                .e-btn-icon
                    color: #fff

        .e-dlg-header
            color: #fff

    .e-dlg-content
        border-bottom-left-radius: var(--border-radius)
        border-bottom-right-radius: var(--border-radius)

        .e-xlfl-maindiv
            background: var(--gray-lighter)
            margin-top: 45px
            padding: 0 15px 15px 15px

            .e-xlfl-fieldset
                font-size: var(--body-font-size)
                font-family: 'Avenir-Heavy'
                text-transform: uppercase
                color: var(--ink)

            .e-xlfl-radiodiv
                width: unset

            .e-radio-wrapper
                .e-label
                    text-transform: lowercase
                    color: var(--ink)
                    font-size: var(--body-font-size)
                    padding-left: 30px
                    padding-right: 20px

                .e-xlfl-radio-and, .e-xlfl-radio-or
                    &::before
                        border-color: var(--primary_one)

                    &::after
                        background-color: var(--primary_one)
                        color: var(--primary_one)

                .e-label
                    font-family: 'Avenir-Book'

            .e-checkbox-wrapper
                .e-label
                    text-transform: capitalize
                    color: var(--ink)
                    font-size: var(--body-font-size)
                    margin-left: 15px
                
                .e-frame
                    border: 1px solid var(--ink-lighter)
                    
                .e-check
                    border-color: var(--ink-lighter)

                .e-frame.e-check
                    border-color: var(--primary_one)
                    background-color: var(--primary_one)

                .e-label
                    font-family: 'Avenir-Book'

            .e-xlfl-dlgfields
                position: absolute
                top: 75px
                left: 20px
                font-size: 14px
                font-family: 'Avenir-Book'
                color: var(--ink)

            .e-xlfl-table
                display: flex
                flex-direction: column

                tr
                    margin-bottom: 15px
                    
                .e-xlfl-fields
                    display: flex
                    width: 588px
                    justify-content: space-between

                    .e-xlfl-optrdiv, .e-xlfl-valuediv
                        background: #fff
                        border-top-left-radius: var(--border-radius)
                        border-top-right-radius: var(--border-radius)
                        border-bottom-right-radius: var(--border-radius)
                        border-bottom-left-radius: var(--border-radius)
                        border: 1px solid var(--border-color)
                        padding-top: 0
                        width: 264px

                        .e-clear-icon
                            position: relative
                            right: 10px
                            @include knIcon(37px)

                        input::selection
                            @include inputSelectedText

                        .e-input-focus
                            outline: 2px solid var(--primary_one)
                            border-radius: var(--border-radius)

                        .e-input-group
                            padding: 4px 0 4px 10px
                            border: none 
                            margin: 0
                            font-family: 'Avenir-Book'
                            color: var(--body-font-size)

                            input::placeholder
                                color: var(--gray-darker)
                                font-style: italic

                            &::before, &::after
                                display: none

                            .e-input-group-icon
                                @include knIcon(31px)
                                margin: 0 4px
                                padding: 0

                                &::before
                                    font-family: 'Font Awesome 6 Pro'
                                    content: '\f078'
                        
                        //DateTime pickerdropdown picker
                        .e-datetime-wrapper
                            .e-date-icon
                                @include knIcon(34px)

                                &::before
                                    font-family: "Font Awesome 6 Pro"
                                    content: "\f783"
                            
                            .e-time-icon
                                @include knIcon(34px)
                                
                                &::before
                                    font-family: "Font Awesome 6 Pro"
                                    content: "\f017"
                            
    
    .e-footer-content
        @include syncFusionDialogFooter(none)

//Dialog 
.e-contextmenu-wrapper
    border-bottom: 1px solid var(--border-color)
    border-bottom-left-radius: var(--border-radius)
    border-bottom-right-radius: var(--border-radius)
    border-bottom-top-radius: var(--border-radius)
    border-bottom-top-radius: var(--border-radius)

    .e-contextmenu
        box-shadow: var(--box-shadow-elv-2) !important
        @include dialogBorder

    ul
        .e-separator
            margin-top: 6px !important
            border-top: 1px solid var(--border-color) !important

        li, .e-menu-item
            border-top: 1px solid #fff
            border-bottom: 1px solid #fff
            height: unset !important
            font-family: 'Avenir-Book'
            color: var(--ink)

            &:hover
                @include dropdownHover
        
        .e-selected.e-focused
            @include dropdownHover

.e-popup
    width: 264px

    .e-content
        .e-list-parent
            li, .e-list-item
                font-family: 'Avenir-Book'

                &:hover, &.e-active, &.e-item-focus
                    @include dropdownHover

.e-datepicker.e-popup-wrapper, .e-bigger.e-small .e-datepicker.e-popup-wrapper
    @include dialogBorder

//Freeze column
.e-grid.e-lib .e-rightfreeze.e-freezerightborder, .e-grid .e-rightfreeze.e-freezerightborder
    border-left-color: transparent
    border-left: 0

.e-grid.e-lib .e-leftfreeze.e-freezeleftborder, .e-grid .e-leftfreeze.e-freezeleftborder
    border-right-color: transparent
    border-right: 0
    
.e-grid.e-right-shadow .e-rightfreeze.e-freezerightborder:not(.e-dragborder)
    box-shadow: none
        
//Search box and toolbar
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left, .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center, .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right
    top: -3px

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right
    left: 2px
    right: unset

.e-toolbar.e-control[class*=e-toolbar]
    width: 265px !important
    top: -40px
    position: absolute
    background: transparent

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper
    padding: 0

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search
    display: flex
    align-items: center
    opacity: 1
    height: 30px
    font-family: 'Avenir-Book'
    font-size: 14px
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius)

    &.e-input-focus
        outline: 2px solid var(--primary_one)
        border: 1px solid transparent !important

    input
        border: 0
        height: 22px
        padding-left: 8px

//clear icon
.e-grid .e-toolbar .e-toolbar-item.e-search-wrapper .e-sicon.e-clear-icon
    margin: 0
    position: absolute

.e-grid .e-toolbar .e-toolbar-item.e-search-wrapper .e-sicon.e-clear-icon::before
    @include knIcon()

//search icon
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search .e-search-icon
    border-left: 1px solid var(--border-color) !important
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    margin: 0
    font-size: 16px
    padding: 6px 9px

    &:hover
        background: var(--primary_one-lighter)