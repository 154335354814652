
.kn-header
    width: 100%
    height: 80px
    left: 0
    right: 0
    background-color: $white
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%)
    position: absolute
    z-index: 2
    display: flex
    justify-content: center
    align-items: center
    z-index: $masthead-elevation
    top: 0

    &.kn-compressed
        height: 60px

    &.light
        background-color: $white
    
    &.dark
        background-color: $primary_one

        nav
            .branding
                .version
                    color: $white


    nav
        display: flex
        width: 100%
        padding: 0 20px
        color: $primary_one
        align-items: center
        justify-content: space-between

        .branding
            display: flex
            width: max-content
            flex-wrap: nowrap
            align-items: center
            justify-content: space-between

            .logo
                cursor: pointer
                display: flex

                img
                    max-height: 46px
                    max-width: 250px


            .version
                color: $ink
                font-size: 16px
                margin-left: 15px
                font-family: 'Avenir-Heavy'
                width: max-content

        .main-nav
            margin-left: 20px
            flex: 100%
            overflow: hidden
            position: relative
            position: relative

            .header-masthead-nav
                .kn-nav-container 
                    display: flex
                    
                    > div, > div > ngx-dynamic-hooks
                        display: flex
                        width: max-content
                        align-items: center
                   
                    .kn-header-nav
                        display: flex
                        width: max-content

                        &.hide
                            visibility: hidden
                            position: absolute
                            z-index: -10 

                            .link::before
                                visibility: hidden
                                transition: none

                    .kn-masthead-overflow-container
                        .link
                            padding: 0
                            
                            .kn-menu-btn
                                .kn-icon
                                    &:hover
                                        background: none
                                        
                        .kn-dropdown
                            display: flex
                            flex-direction: column

                            .kn-dropdown-item
                                text-decoration: none
                                color: $ink

                                &.active
                                    cursor: pointer
                                    color: $primary_one
                                    font-family: 'Avenir-heavy'

                        &.hide
                            visibility: hidden

                            .active-link::before
                                display: none
                            
                            .kn-menu-btn
                                .kn-icon
                                    transition: none

        .right-col
            > div, ngx-dynamic-hooks
                display: flex
                justify-content: flex-end
                align-items: center
                //min-width: 300px
                width: auto

                > *
                    margin: 10px
                
                > *:first-child
                    margin-left: 0
                
                > *:last-child
                    margin-right: 0

                .resource-center-icon
                    color: $ink-light
                    padding: 6px

                    &:hover
                        color: $primary_one

                .divider
                    border-left: 1px solid $gray
                    height: 30px
                    margin: 0px 40px

                .client-name
                    display: inline-block
                    position: relative
                    color: $ink
                    font-size: 16px
                    font-family: Avenir-Medium

                .dropdown
                    display: inline-block
                    cursor: pointer
                    float: right
                    margin: 0 20px

                    .dropdown-menu
                        min-width: 138px
                        width: 138px
                        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%)
                        border: 1px solid $gray-darker
                        left: auto !important
                        right: 0
                        position: relative
                        margin-right: -5px

                    .dropdown-item
                        color: $ink
                        font-size: 14px

                        &:hover
                            background: $primary_one-lighter
                            border: solid $primary_one-med-light
                            border-width: 1px 0px

                            span
                                font-family: Avenir-Heavy
                        i
                            color: $ink-light
                            font-size:16px
                            vertical-align: text-bottom
                            padding-right: 6px

                    .user-name
                        .user-icon
                            margin: 0 10px

                        i
                            color: $ink-light

                    .user-icon-mobile
                        display: none

        // .header-links
        //     display: flex
        //     margin: 0 40px

        //     .links
        //         margin: 0
        //         position: relative
        //         padding: 0 20px

        //     .divider
        //         font-size: 22px
        //         color: $gray-darker
        //         position: relative
        //         top: -4px

        .title
            padding: 0 20px
            font-size: 18px
            font-family: 'Avenir-Heavy'


.rotate
    transform: rotate(180deg)

//Tablet
@media (max-width: 992px)
    .header
        .header-nav
            .left-nav
                margin: 0 auto

            .dropdown
                .user-icon-desktop
                    display: none

                .user-icon-mobile
                    display: block

            .header-links
                display: none