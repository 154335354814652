
:root
    --box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%)
    --box-shadow-elv-2: 0 1px 2px 1px rgb(0 0 0 / 10%)
    --box-shadow-elv-4: 0 1px 8px 2px rgb(0 0 0 / 16%)
    --box-shadow-elv-8: 0 4px 8px 4px rgb(0 0 0 / 18%)
    --box-shadow-elv-12: 0 12px 12px 4px rgb(0 0 0 / 20%)
    --box-shadow-rev-elv-2: 0 1px 2px 3px rgb(0 0 0 / 10%)

$box-shadow: var(--box-shadow)
$box-shadow-elv-2: var(--box-shadow-elv-2)
$box-shadow-elv-4: var(--box-shadow-elv-4)
$box-shadow-elv-8: var(--box-shadow-elv-8)
$box-shadow-elv-12: var(--box-shadow-elv-12)

//for the top border of an element
$box-shadow-rev-elv-2: var(--box-shadow-rev-elv-2)