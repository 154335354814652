
/*Global styles*/
@import ./variables
@import ./animations
@import ./mixins
@import ./fonts
@import ./motion
@import ./layout
@import ./scrollbar
@import ./typography
@import ./elevation
@import ./body
@import ../assets/fontawesome-pro-6.1.2-web/css/all
@import ../assets/custom-icons/style


/*components*/
@import ./grid
@import ./icons
@import ./buttons
@import ./masthead
@import ./accordion
@import ./tabs
@import ./links
@import ./toggles
@import ./form-elements
@import ./checkbox
@import ./modal
@import ./multi-select
@import ./tooltip
@import ./radio-button
@import ./autocomplete
@import ./alerts
@import ./footer
@import ./panel
@import ./contextual-menu
@import ./progress-indicator
@import ./toast
@import ./chip
@import ./slider
@import ./button-group
@import ./spin-box
@import ./calendar
@import ./syncfusion
@import ./date-picker
@import ./spreadsheet
