.tile
    height: 80vh
    overflow: hidden
    margin: 25px 20px 0 20px

    .section-container
        max-width: 100%

    .kn-spacer
        margin: 55px 0

    .tab-content 
        overflow-y: scroll
        height: 60vh
        padding-right: 40px

        .code-example 

            &.sm
                width: 700px

            .example-col
                border: 2px solid var(--primary_one-med-light)
                background: var(--primary_one-lightest)
                height: 84px

        .code-block
            position: relative
            border-radius: var(--border-radius)
            margin-top: 20px
            padding: 20px
            background: var(--ink-dark)  
            color: var(--secondary_one-med-light)  

            pre
                white-space: pre-wrap

            .copy-icon
                position: absolute
                right: 10px
                top: 10px

