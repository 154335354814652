
$acc-title-bg-color: #fff

.kn-accordion 
    color: $ink
    ::-webkit-scrollbar-track
        background-color: $ink-light

    [kn-group|="first"], [kn-group|="second"]
        border-bottom: 1px solid $gray-light

    &.kn-acc-filled-bg
        label, .kn-sub-group-list label
            background-color: $gray-lightest

            &:hover
                background-color: $gray-light

        input[type=checkbox]:checked + label
            background-color: $cloud-gray

            &:hover
                background-color: $gray-light

        label[kn-group="first"], label[kn-group="second"]
            border-bottom: 2px solid $white

        label[kn-group="second"]:first-of-type
            border-top: 1px solid $white

        .kn-sub-group-list 
            label
                border-top: 2px solid $white
        
        input[type=checkbox]:checked + label + .kn-acc-item
            border-bottom: none

    //global sub title styling
    label:has(.sub-title)
        .kn-title-container
            &.title-icon
                .sub-title
                    left: 32px
                
    label 
        display: flex
        align-items: center
        justify-content: space-between
        padding: 8px 16px
        height: max-content
        min-height: 48px
        background-color: $acc-title-bg-color
        font-family: 'Avenir-Book'
        cursor: pointer
        font-size: 14px

        &.disabled
            pointer-events: none
            color: $gray-darker

            i
                color: $gray-darker

        &.kn-acc-active-link
            background: $gray-lighter

        .kn-title-container:has(i)
            i
                margin-right: 16px

            .sub-title
                margin-left: 32px

        .kn-acc-title
            display: flex
            align-items: center

        .sub-title
            font-size: 12px
            font-family: 'Avenir-Book'
            position: relative
            
        .kn-icon 
            order: 1
            transition: transform $kn-transition-250 ease

        i
            color: $ink-light

        &:focus,
        &:hover 
            background: $cloud-gray
            font-weight: 900

    //if the chevron control is left aligned
    &.icon-left-aligned
        label
            justify-content: flex-start

        label:has(.sub-title)
            .kn-title-container
                .sub-title
                    margin-right: 0 !important
                    
        .kn-icon
            order: 0

        .kn-sub-group-list 
            label 
                padding-left: 54px

            .kn-sub-sub-group-list 
                label 
                    padding-left: 95px

        .kn-title-container
            margin-left: 16px

    //sub-title with inline
    &.sub-title-inline
         label:has(.sub-title)
            height: unset
            align-items: center

            .kn-title-container
                display: flex
                justify-content: space-between
                width: 100%  

                .sub-title
                    position: relative  
                    margin-right: 16px 
                    left: 0

    .kn-acc-list 
        margin-top: 0
                
    //reset the height when checkbox is checked
    input[type=checkbox]:checked + label + .kn-acc-item 
        max-height: 1000px
        border-bottom: 1px solid $gray-light

    input[type=checkbox]:checked + label
        background-color: $gray-lightest
        border-bottom: 1px solid $gray-lightest
        font-family: 'Avenir-Heavy'

        &:hover
            background: $cloud-gray
            border-bottom: 1px solid $cloud-gray

    input[type=checkbox]:checked + label 
        .kn-icon
            transform: rotate(180deg)

    label[kn-group="second"]:first-of-type
        border-top: 1px solid $gray-light

    //Styling first level lists items
    .kn-tier-one-list
        width: 100%

        label 
            padding-left: 32px

    //Styling second level list items
    .kn-tier-two-list 
        input[type=checkbox]:checked + label
            border-bottom: 1px solid $gray-light

        label 
            padding-left: 48px
            background-color: $acc-title-bg-color

            &:focus,
            &:hover 
                background: $gray-light
        
        label:last-of-type
            border-bottom: 0

    //Styling third level list items
    .kn-tier-three-list 
        label 
            padding-left: 48px
            background-color: $acc-title-bg-color

    //Hide nested lists
    .kn-tier-one-list,
    .kn-tier-two-list,
    .kn-tier-three-group-list 
        height: 100%
        max-height: 0
        overflow: hidden
        background-color: $white
        transition: max-height $kn-transition-250 ease-in-out

    