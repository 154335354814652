.canvas-container #componentTabs .container .code-container
    &#kraken-chip
        .kn-component
            .chip-container
                display: flex

                .chip
                    margin: 10px

.fill-color-container
    display: flex
    align-items: center
    
    .swatch
        width: 16px
        background-color: rgb(73, 153, 201)
        display: block
        height: 16px
        margin-right: 2px
            
