
body,html 
    margin: 0
    padding: 0
    font-size: 14px
    font-family: 'Avenir-Book'
    line-height: 23px
    scroll-behavior: smooth
* 
    box-sizing: border-box

//global text selection styling
::selection
    @include inputSelectedText
    
//body text
.kn-body-med
    font-family: 'Avenir-Medium'
    font-size: 14px

.kn-body-heavy
    font-family: 'Avenir-Heavy'
    font-size: 14px

.kn-body-12
    font-family: 'Avenir-Book'
    font-size: 12px

.kn-body-16
    font-family: 'Avenir-Book'
    font-size: 16px

.kn-bold
  font-family: 'Avenir-Heavy'

//common elements
.kn-spacer
    height: 1px
    margin: 10px 0

//pipe separator
.kn-pipe
    border-left: 1px solid $gray
    height: $pipe-height
    position: relative
    margin: 0 8px

.kn-divider
    border-top: 1px solid $border-color
    margin: 10px 0