
.kn-button-group

    .kn-button-group-container
        display: flex
        
        .kn-btn
            min-width: 0
            min-height: 36px

            &.kn-btn-sm
                min-height: 28px
                
        .kn-btn.kn-tertiary
            &.active
                background: var(--primary_one-lighter)
                
                &.disabled
                    background: var(--gray-light)
                    border-color: var(--gray-darker)
                    color: var(--gray-darker)
        
        .kn-btn.kn-primary
            &.active
                &.disabled
                    background: var(--gray-darker)
                    color: var(--white)

                &.disabled
                    background: var(--gray-light)
                    border-color: var(--gray-darker)
                    color: var(--gray-darker)

        .kn-btn.kn-secondary
            &.active
                background: var(--primary_one-med-light)
                border-top-color: var(--primary_one-med-light) 
                border-bottom-color: var(--primary_one-med-light) 

                &.disabled
                    background: var(--gray-darker)
                    color: var(--white)

            &.disabled
                background: var(--gray-light)
                border-color: var(--gray-light) !important
                color: var(--gray-darker)

    &.column
        .kn-button-group-container
            flex-direction: column

            .kn-btn
                width: 100%
                justify-content: left
               
            kn-button:first-child
                .kn-btn
                    border-top-left-radius: 4px
                    border-top-right-radius: 4px

            kn-button:last-child
                .kn-btn
                    border-bottom-right-radius: 4px
                    border-bottom-left-radius: 4px

            kn-button
                .kn-btn
                    border-radius: 0
            
            kn-button
                .kn-btn.kn-secondary
                    border-left-color: var(--primary_one-med-light)
                    border-right-color: var(--primary_one-med-light)

            kn-button:not(:last-child)
                .kn-btn
                    border-bottom: .5px solid $white

                .kn-btn.kn-tertiary
                    border-bottom: .5 solid var(--primary_one-lighter)

    &.row   
        .kn-button-group-container
            .kn-btn.active + .kn-btn.active
                border-right: 0

            kn-button:first-child
                .kn-btn
                    border-top-left-radius: 4px
                    border-bottom-left-radius: 4px

            kn-button:last-child
                .kn-btn
                    border-top-right-radius: 4px
                    border-bottom-right-radius: 4px

            kn-button
                .kn-btn
                    border-radius: 0

            kn-button:not(:last-child)
                .kn-btn.kn-tertiary
                    border-right: none

                .kn-btn.kn-secondary, .kn-btn.kn-primary
                    border-right: 1px solid rgba(255, 255, 255, .35)
            