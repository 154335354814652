
.kn-inline-tile
    width: 100%
    display: flex

    .kn-tile:first-child
        margin-right: 10px

    .kn-tile:last-child
        margin-left: 10px

.kn-tile:only-child
    margin-top: 0
    
.kn-tile
    border-radius: 5px
    font-family: 'Avenir-Book'
    padding: 20px
    margin-top: 20px
    width: 100%
    border: 1px solid var(--border-color)

    &.kn-tile-25
        width: 25%

    &.kn-tile-50
        width: 50%
    
    &.kn-tile-75
        width: 75%

    &.filled
        background: $gray-lighter
        border: none

    &.warning
        border: 2px solid var(--secondary_one)

    &.error
        border: 2px solid var(--kn-danger)

    .kn-row
        display: flex
        justify-content: space-between
        width: 100%
        align-items: baseline
        margin-bottom: 48px

        &.align-center
            align-items: center

        &.kn-row-label
            margin-bottom: 10px
            align-items: baseline
            justify-content: flex-start

            .row-label
                width: $container-column-width

            .row-value
                font-family: 'Avenir-Heavy'
        
        .row-item-label
            width: $container-column-width

        .row-item-value
            width: $container-column-width
            font-family: 'Avenir-Heavy'

        .kn-col:first-child
            margin-left: 0

        .kn-col:last-child
            margin-right: 0  

        .kn-col
            width: 100%
            margin: 0 24px

            &.kn-col-25
                width: 25%

            &.kn-col-75
                width: 75%

    .kn-row:first-child
        margin-top: 10px

    .kn-row:last-child
        margin-bottom: 10px

    .kn-row:only-child
            margin-bottom: 10px
