.kn-spin-box
    &.value-right
        .kn-spin-box-container
            .kn-input-contain .inner .kn-form-control
                text-align: right
    
    &.compressed
        .kn-spin-box-btn-container
            height: 30px

            .kn-spin-box-btn
                height: 14px

                i
                    font-size: 15px
                
                &.up
                    i
                        top: 2px

    .kn-spin-box-label
        text-transform: uppercase
        color: var(--ink)
        font-size: 12px
        font-family: "Avenir-book"
        word-wrap: break-word

    .kn-spin-box-container
        kn-textbox
            width: 100%

    .error
        color: var(--kn-danger)  
        position: fixed
        margin-top: 4px

        .error-message
            margin-left: 6px

    .kn-spin-box-btn-container
        border: 1px solid var(--border-color)
        border-radius: var(--border-radius)
        height: 39px
        width: 31px
        text-align: center

        .kn-spin-box-btn
            position: relative
            height: 18.5px
            cursor: pointer

            &.disabled
                pointer-events: none
                i
                    color: var(--gray-darker)

            i
                font-size: 20px
                position: relative
                pointer-events: none
                color: var(--ink-light)

            &:hover
                background: var(--primary_one-lightest)

                i
                    color: var(--primary_one)

            &.up
                i
                    top: 5px

            &.down
                i
                    top: -7px
            
            
    &.disabled
        pointer-events: none

        .kn-spin-box-btn-container
            border-color: var(--gray-darker)

        .kn-spin-box-btn
            i
                color: var(--gray-darker)

    &.read-only
        pointer-events: none
        
        .kn-spin-box-btn-container
            border-color: var(--gray-darker)
            background: var(--gray-light)

        .kn-spin-box-btn
            i
                color: var(--gray-darker)

        &.horizontal
            .kn-spin-box-btn
                border-color: var(--gray-darker)
                background: var(--gray-light)

    .kn-input-contain .inner .kn-form-control
        padding: 8px 10px

    //focus state for  the textbox
    .kn-input-contain .inner .kn-form-control:focus, .kn-input-contain .inner .kn-form-control input:focus
        border: 1px solid var(--primary_one)
        box-shadow: inset 0 0 0 1px var(--primary_one)
        outline: unset

    //error highlight
    .kn-input-contain .inner .kn-form-control.input-error
        border: 1px solid var(--kn-danger)
        box-shadow: inset 0 0 0 1px var(--kn-danger)
        outline: unset

    &.vertical-right
        .kn-spin-box-container
            display: flex

            .kn-spin-box-btn
                    
                &.up
                    &:hover
                        border-top-right-radius: var(--border-radius)

                &.down
                    &:hover
                        border-bottom-right-radius: var(--border-radius)

            .kn-spin-box-btn-container
                order: 1
                border-top-left-radius: 0
                border-bottom-left-radius: 0
                border-left: 0

            .kn-input-contain .inner .kn-form-control
                border-top-right-radius: 0
                border-bottom-right-radius: 0

    &.vertical-left
        .kn-spin-box-container
            display: flex

            .kn-spin-box-btn-container
                order: 0
                border-top-right-radius: 0
                border-bottom-right-radius: 0
                border-right: 0

                .kn-spin-box-btn
                    
                    &.up
                        &:hover
                            border-top-left-radius: var(--border-radius)

                    &.down
                        &:hover
                            border-bottom-left-radius: var(--border-radius)

            .kn-input-contain .inner .kn-form-control
                border-top-left-radius: 0
                border-bottom-left-radius: 0

    &.horizontal
        &.compressed
            .kn-spin-box-container
                .kn-spin-box-btn
                    height: 30px

        .kn-spin-box-container
            display: flex

            .kn-spin-box-btn
                position: relative
                height: 39px
                width: 31px
                cursor: pointer
                text-align: center
                border: 1px solid var(--border-color)
                border-radius: 4px
                display: flex
                justify-content: center
                align-items: center

                &.disabled
                    pointer-events: none

                    i
                        color: var(--gray-darker)
                i
                    font-size: 20px
                    position: relative
                    pointer-events: none
                    color: var(--ink-light)


                &:hover
                    i
                        color: var(--primary_one)

                &.up
                    border-top-left-radius: 0
                    border-bottom-left-radius: 0
                    border-left: 0

                    &:hover
                        background: var(--primary_one-lightest)
                        border-bottom-right-radius: var(--border-radius)
                        border-top-right-radius: var(--border-radius)


                &.down
                    border-top-right-radius: 0
                    border-bottom-right-radius: 0
                    border-right: 0

                    &:hover
                        background: var(--primary_one-lightest)
                        border-bottom-left-radius: var(--border-radius)
                        border-top-left-radius: var(--border-radius)

            .kn-input-contain .inner .kn-form-control
                border-radius: 0

