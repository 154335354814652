
.kn-toast-container
    position: fixed
    top: 86px
    margin: 0 auto
    left: 0
    right: 0
    display: flex
    flex-direction: column
    align-items: center
    width: max-content
    z-index: 1010

    &.compressed
        top: 66px

    .kn-toast
        position: relative
        opacity: 0
        border-radius: $border-radius
        box-shadow: $box-shadow-elv-2
        color: $white
        max-width: 440px
        min-width: 200px
        width: max-content
        padding: 17px
        transition: 1s
        margin: 6px 0

        .kn-toast-body
            display: flex
            align-items: baseline
            justify-content: left

            i
                padding-right: 10px
                position: relative
                top: 2px

        &.success
            background: $green-dark

        &.info
            background: $ink-light

        &.show
            opacity: 1
        
        &.hide
            opacity: 0
        