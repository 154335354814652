
.kn-panel
    width: 200px
    height: 100%
    top: 0
    overflow: hidden
    color: $ink
    border: 1px solid $border-color
    background: $white
    border-radius: 4px
    z-index: 100

    &.hidden
        visibility: hidden

    .kn-panel-header
        padding: 16px
        display: flex
        align-items: center

        .kn-right-col
            margin-left: auto
            display: flex
            align-items: baseline

            .kn-header-actions
                margin-right: 8px

                &.hidden
                    visibility: hidden
                    width: 0
                    height: 0


    .kn-panel-body
        padding: 16px
        font-family: 'Avenir-Book'

    &.maximized, &.minimized
        transition: $kn-transition-200

    &.top, &.left, &.right
        &.drop-shadow
            box-shadow: $box-shadow-elv-2

    &.bottom
        &.drop-shadow
            box-shadow: $box-shadow-rev-elv-2
            

    &.kn-slider
        position: absolute
    
    &.inline-slider
        overflow-x: hidden

        .kn-panel-header
            &.minimized
                justify-content: center

                .kn-header-title
                    margin-top: 100px
                    position: absolute
                    opacity: 0
                    animation-name: rotateHeaderTitle270
                    animation-fill-mode: forwards
                    animation-duration: $kn-transition-400
                
                .kn-right-col
                    margin-left: 0
                    
                    .kn-header-actions
                        display: none

            .kn-header-title
                opacity: 0
                animation-name: rotateHeaderTitle90
                animation-fill-mode: forwards
                animation-duration: $kn-transition-400

        .kn-panel-body
            position: relative
            visibility: hidden
            padding: 16px
            font-family: 'Avenir-Book'
            transition: $kn-transition-200


            &.show
                visibility: visible

        &.left
            .min-close
                transform: rotate(0deg)

            .max-close
                transform: rotate(180deg)

        &.right
            .min-close
                transform: rotate(180deg)

            .max-close
                transform: rotate(0deg)

    &.kn-slider:not(.inline-slider)
        transition: $kn-transition-200

    &.left
        border-left: none
        border-top: none
        border-bottom: none
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        right: unset !important
        top: 0 !important

    &.right
        border-right: none
        border-top: none
        border-bottom: none
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        left: unset !important
        top: 0 !important

    &.top
        border-top: none
        border-left: none
        border-right: none
        border-top-left-radius: 0
        border-top-right-radius: 0
        width: 100%
        height: 200px
        right: 0 !important
        left: 0 !important
        bottom: unset !important

    
    &.bottom
        border-bottom: none
        border-left: none
        border-right: none
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        width: 100%
        height: 200px
        right: 0 !important
        left: 0 !important
        top: unset !important

    .panel-close
        transition: $kn-transition-200

.open, .close
    transition: $kn-transition-200

@keyframes rotateHeaderTitle270
    0%
        opacity: 0
        transform: rotate(90deg)
    
    50%
        opacity: 0
        transform: rotate(270deg)
    
    100%
        opacity: 1
        transform: rotate(270deg)

@keyframes rotateHeaderTitle90
    0%
        opacity: 0
        transform: rotate(270deg)
    
    50%
        opacity: 0
        transform: rotate(0deg)
    
    100%
        opacity: 1
        transform: rotate(0deg)
       