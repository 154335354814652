
#iconCatalog
    .icon-container
        padding: 20px

        .info
            width: 59%
            min-width: 59%
            margin-bottom: 40px

        .icon-search
            display: flex
            align-items: center
            justify-content: space-between

            .kn-input-contain 
                .inner 
                    width: 400px

                    #iconInput
                        width: 400px
            
            .icon-count
                font-family: 'Avenir-heavy'
                font-size: 16px

        .results-container
            height: 49vh
            padding: 20px
            margin: 20px 0
            border: 1px solid var(--border-color)
            border-radius: var(--border-radius)
            overflow: auto
            background: var(--gray-lighter)

            .results
                display: flex
                flex-wrap: wrap
                justify-content: flex-start

                .item
                    width: 184px
                    height: 100px
                    margin: 13px
                    transition: var(--kn-transition-250)
                    border-radius: var(--border-radius)
                    cursor: pointer
                    background: var(--white)
                    border: 1px solid var(--white)

                    .item-container
                        display: flex
                        align-items: center
                        justify-content: center
                        padding: 15px
                        flex-direction: column
                        text-align: center
                        color: var(--ink)

                        i
                            font-size: 30px

                        .icon-title
                            margin: 8px 0
                            font-family: 'Avenir-Heavy'
                        
                    &:hover
                        background: var(--primary_one-lighter)
                        border: 1px solid var(--primary_one-med-light)
                        color: var(--primary_one)

#iconModal
    .icon-info
        display: flex
        background: var(--gray-lightest)
        padding: 20px
        border-radius: var(--border-radius)

        .icon-demo
            width: 150px
            height: 172px
            background: var(--white)
            margin-right: 30px
            display: flex
            align-items: center
            justify-content: center
            border-radius: var(--border-radius)

            i
                transition: var(--kn-transition-200)

        .icon-spec
            .title
                font-size: 24px
                font-family: 'Avenir-heavy'
                margin-bottom: 15px

            .item
                display: flex
                align-items: center
                width: 100%

                .value
                    font-family: 'Avenir-heavy'
                    margin-left: 5px
    .icon-code
        margin-top: 20px
        position: relative

        .copy
            position: absolute
            right: 20px
            top: 73px
            cursor: pointer

        .size-container
            display: flex
            align-items: center
            background: var(--gray-lightest)
            border-top-left-radius: var(--border-radius)
            border-top-right-radius: var(--border-radius)
            padding: 15px
            
            .kn-btn
                margin: 0 10px

        #iconHtmlTabs
            .kn-tab-header
                background: var(--ink-dark)
                .kn-tabs    
                    background: var(--ink-dark)

                    .link
                        color: var(--white)

            .kn-tab-content
                background: var(--ink-dark)
                border-bottom-left-radius: var(--border-radius)
                border-bottom-right-radius: var(--border-radius)

                .html
                    text-align: center
                    width: 100%
                    color: var(--secondary_one-med-light)

                    pre
                        display: flex
                        align-items: center
                        justify-content: center
                        margin: 0 auto
                        padding: 40px 15px
                        overflow-x: auto
                        white-space: pre-wrap
                        word-wrap: break-word

                        code
                            transition: var(--kn-transition-200)

                            &.copied
                                font-size: 12px
                                color: var(--white)

                    &.hide
                        animation: hideHTML
                        animation-fill-mode: forwards
                        animation-duration: var(--kn-transition-200)

                        .code-tabs
                            display: none
                        pre
                            display: none
