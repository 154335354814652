
$slider-height: 4px

@mixin thumb($bgColor)
  appearance: none
  pointer-events: auto
  border: 1px solid $white
  height: 16px
  width: 16px
  border-radius: 25px
  background: $bgColor
  cursor: pointer
  transition: $kn-transition-100 ease-in-out
  
  &:hover
    height: 20px
    width: 20px
    box-shadow: 0 0 1px 5px rgba(198, 222, 237, 0.6)
    transform: translateX(-4%)
    top: -10px

.kn-slider 
  .kn-error
    color: $knDanger
    margin: 10px 0

    i
      margin-right: 5px

  .kn-slider-label
    color: var(--ink)

  &.disabled
    input[type=range]
      pointer-events: none !important
  
      &::-webkit-slider-thumb
        pointer-events: none !important

      &::-moz-range-thumb 
        pointer-events: none !important

    .kn-slider-label
      color: $gray-darker

    .slider-inner-container
      .kn-slider-container
        .range
          &::before, &::after 
            color: $gray-darker

          input[type=range]
            &::-webkit-slider-thumb
              @include thumb($gray-darker)

            &::-moz-range-thumb 
              @include thumb($gray-darker)

      .kn-multi-range-container
        .range-slider
          .range-selected 
            background-color: $gray-darker

        .range-input
          &::before, &::after
            color: $gray-darker

          input[type=range]
            &::-webkit-slider-thumb
              @include thumb($gray-darker)

            &::-moz-range-thumb 
              @include thumb($gray-darker)

  &.read-only:not(.hide-textbox)
    input[type=range]
        pointer-events: none !important
    
        &::-webkit-slider-thumb
          display: none !important

        &::-moz-range-thumb 
          display: none !important

  &.read-only
    .slider-inner-container
      .kn-slider-container
        .range
          input[type=range]
            &::-webkit-slider-thumb
              @include thumb($ink-lighter)

            &::-moz-range-thumb 
              @include thumb($ink-lighter)

      .kn-multi-range-container
        .range-slider
          .range-selected 
            background-color: $ink-lighter

        .range-input
          input[type=range]
            &::-webkit-slider-thumb
              @include thumb($ink-lighter)

            &::-moz-range-thumb 
              @include thumb($ink-lighter)
  
  &.multi-thumb
    .slider-inner-container
      .kn-range-textbox:first-child
        margin-left: 0
        margin-right: 20px

  .kn-slider-label
      font-family: 'Avenir-Heavy'
      margin: 5px 0

  .slider-inner-container
    display: flex
    align-items: center

    .tooltip 
      @include tooltipContainer()
      position: absolute
      display: none
      top: -60px
      
      &.top
        @include tooltipArrowDown()

    .kn-range-textbox
      width: 50px
      min-width: 50px
      margin-left: 20px

      .kn-form-control
        padding: 8px
        text-align: center

    .kn-slider-container 
      position: relative
      width: 100%

      .range 
        background-size: calc(100%/((var(--max) - var(--min)) / var(--step)) - .1%) 12px
        position: relative

        &.with-steps
          --ticksThickness: 2px
          --ticksHeight: 12px
          background: linear-gradient(to right, $gray var(--ticksThickness), transparent 1px) repeat-x
          background-size: calc(100%/((var(--max) - var(--min)) / var(--step)) - .1%) 12px
          background-position: 0 8px
          position: relative

      /* min / max labels at the edges */
      .range::before, .range::after 
        color: var(--ink)
        font: 12px monospace
        content: counter(x)
        position: absolute
        bottom: -2ch
        font-family: 'Avenir-Heavy'

      .range::before 
        color: var(--ink)
        counter-reset: x var(--min)
        transform: translateX(-50%)
        left: 4px

      .range::after
        counter-reset: x var(--max)
        right: 10px
        transform: translateX(50%)

      input[type=range]
        appearance: none
        -webkit-appearance: none
        width: 100%
        cursor: pointer
        outline: none
        border-radius: 16px
        height: 4px
        background: $gray
        border-radius: 16px

        &::-webkit-slider-thumb
          @include thumb($primary_one)
        &::-moz-range-thumb 
          @include thumb($primary_one)

    //Multi thumb slider
    .kn-multi-range-container
      position: relative
      width: 100%

      .tooltip.top
        margin-top: 0

      .range-slider 
        height: $slider-height
        position: relative
        background-color: $gray
        border-radius: 2px
        z-index: 10
        top: 5px

        .range-selected 
          height: 100%
          left: 30%
          right: 30%
          position: absolute
          border-radius: $slider-height
          background-color: $primary_one

      .range-input 
        position: relative
        width: 100%
        background-size: calc(100%/((var(--max) - var(--min)) / var(--step)) - .1%) 12px
        height: 12px

        .ticks
          --ticksThickness: 2px
          --ticksHeight: 12px
          background: linear-gradient(to right, $gray var(--ticksThickness), transparent 1px) repeat-x
          background-size: calc(100%/((var(--max) - var(--min)) / var(--step)) - .1%) 12px
          background-position: 0 0
          position: relative
          height: 12px
          top: -3px
          z-index: 0

        &::before, &::after 
          font: 12px monospace
          content: counter(x)
          position: absolute
          bottom: -2ch
          font-family: 'Avenir-Heavy'

        &::before 
          counter-reset: x var(--min)
          transform: translateX(-50%)
          left: 3px

        &::after
          counter-reset: x var(--max)
          right: 10px
          transform: translateX(50%)

      .range-input input 
        position: absolute
        width: 100%
        height: $slider-height
        top: -1px
        background: none
        pointer-events: none
        -webkit-appearance: none
        -moz-appearance: none
        outline: none
        z-index: 10

      .range-input input::-webkit-slider-thumb 
        @include thumb($primary_one)
      
      .range-input input::-moz-range-thumb 
        @include thumb($primary_one)

