
$accOpened: #373d42
$accOpened-hover: #3c4348

.side-nav
    width: 20vw
    max-width: 245px
    height: 100vh
    position: relative
    background: var(--ink-light)
    left: 0
    overflow-y: auto
    
    .site-map-search
        top: 60px
        padding: 15px
        position: fixed
        z-index: 10
        width: inherit
        max-width: inherit
        background: var(--ink-light)

    .menu
        top: 145px
        position: relative
        overflow-y: auto
        height: max-content
        
        .copyright
            text-align: center
            color: var(--ink-lightest)

    .kn-accordion 

        [kn-group|="first"], [kn-group|="second"]
            border-bottom: none

        label 
            color: var(--white)
            background-color: var(--ink-light)

            &.kn-acc-active-link
                background: var(--ink-lighter) !important

            .kn-icon 
                order: 1
                color: var(--white)

            &:focus,
            &:hover 
                background: var(--ink-lighter)

        input[type=checkbox]:checked + label
            background: $accOpened
            border-bottom: none

            &:hover
                background: $accOpened-hover
                border-bottom: none

        input[type=checkbox]:checked + label + .kn-acc-item
            border-bottom: none

        label[kn-group=second]:first-of-type
            border-top: none

        .kn-tier-two-list 
            input[type=checkbox]:checked + label
                border-bottom: none
                
            label 
                background: var(--ink-light)

                &.kn-acc-active-link
                    background: var(--ink-lighter) !important

                &:focus,
                &:hover 
                    background: var(--ink-lighter)

        //Styling third level list items
        .kn-tier-three-list 
            label 
                background: var(--ink-light)

                &.kn-acc-active-link
                    background: var(--ink-lighter)

                &:focus,
                &:hover 
                    background: var(--ink-lighter)
        
        .kn-tier-one-list,
        .kn-tier-two-list,
        .kn-tier-three-list 
            border-bottom: none
    
