
/*Contextual menu*/
.kn-contextual-menu
    *:first-child
        cursor: pointer

    -ms-overflow-style: none  // IE and Edge
    *
        scrollbar-width: none  // Firefox

    ::-webkit-scrollbar, &::-webkit-scrollbar-width
        display: none

    
        
    .kn-dropdown
        width: max-content
        display: flex
        z-index: 1000
        max-width: 264px
        max-height: 260px

        .inner-container
            position: relative
            overflow-y: auto
            max-height: 260px
            width: 100%
            height: 100%

            .kn-dropdown-item
                display: flex
                align-items: center
                justify-content: flex-start

                .item-icon
                    margin-right: 8px
                    color: $ink-light
                    text-shadow: none

                .kn-contextual-menu-meta
                    margin-left: 20px
                    margin-right: 20px

                .sub-menu-arrow
                    margin-left: auto
                    padding-left: 10px
                    color: $ink-light

                &.has-child2-menu
                    .kn-contextual-menu-meta
                        margin-right: 0

                    &:hover
                        .kn-child2-menu
                            visibility: visible
                            opacity: 1
                            animation-name: showContextSubMenu
                            animation-fill-mode: forwards
                            animation-duration: $kn-transition-400
                            z-index: 10

                            &.hide
                                visibility: hidden
                                opacity: 0
                                z-index: -10
            
                &.has-child3-menu
                    &:hover
                        .kn-child3-menu
                            visibility: visible
                            opacity: 1
                            animation-name: showContextSubMenu 
                            animation-fill-mode: forwards
                            animation-duration: $kn-transition-400
                            z-index: 10

                            &.hide
                                visibility: hidden
                                opacity: 0
                                z-index: -10
                            
                &.has-child4-menu
                    &:hover
                        .kn-child4-menu
                            visibility: visible
                            opacity: 1
                            animation-name: showContextSubMenu 
                            animation-fill-mode: forwards
                            animation-duration: $kn-transition-400
                            z-index: 10

                            &.hide
                                visibility: hidden
                                opacity: 0
                                z-index: -10

                .kn-child2-menu, .kn-child3-menu, .kn-child4-menu
                    position: fixed
                    min-width: 132px
                    max-width: 264px
                    max-height: 260px
                    height: max-content
                    width: max-content
                    border: 1px solid $border-color
                    border-radius: $border-radius
                    box-shadow: $box-shadow-elv-4
                    visibility: hidden
                    z-index: -10
                    background: $white
                    font-family: 'Avenir-Book'
                    opacity: 0
                    overflow-y: auto

                    .inner-container
                        .kn-dropdown-item
                            text-shadow: none
                            font-weight: normal
                            
                            .kn-contextual-menu-meta
                                font-weight: normal

                            .kn-child3-menu
                                .inner-container
                                    .kn-dropdown-item
                                        .kn-contextual-menu-meta
                                            font-weight: normal

                                        &:hover
                                            .kn-contextual-menu-meta
                                                @include textHover

                                        .kn-child4-menu
                                            .inner-container
                                                .kn-dropdown-item
                                                    .kn-contextual-menu-meta
                                                        font-weight: normal

                                                    &:hover
                                                        .kn-contextual-menu-meta
                                                            @include textHover
                                            
                            &:hover
                                @include textHover
                                
                                .kn-contextual-menu-meta
                                    @include textHover

                    &::-webkit-scrollbar, &::-webkit-scrollbar-width
                        display: none
            
            .kn-contextual-menu-divider
                border-top: 1px solid $border-color
                margin: 10px 0

        .more-items
            position: absolute
            height: 20px
            width: 100%
            color: $ink-light
            text-align: center
            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px
            display: none
            background: white
            z-index: -10

            i
                text-shadow: none

            .border
                position: absolute
                height: 95%
                width: 98%
                opacity: .8
                bottom: 0
            
            &.up
                top: 0

                .border
                    box-shadow: 0px 0px 18px 13px white

            &.down
                bottom: 0

                .border
                    box-shadow: 0px -15px 14px 18px white
                    
            &.show
                display: block
                z-index: 0