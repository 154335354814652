
.kn-icon
  @include knIcon

.kn-icon-lg
  font-size: 40px

.kn-icon-md
  font-size: 20px

.kn-icon-sm
  font-size: 16px

.kn-spinner
  .kn-spinner-bg
    position: absolute
    width: 100%
    height: 100%
    position: absolute
    background: $white
    left: 0
    right: 0
    top: 0
    opacity: .7
    z-index: 10

  .kn-active-loading
    position: absolute
    background-repeat: no-repeat
    top: 50%
    left: 50%
    margin-top: -50px
    margin-left: -50px
    border-radius: 50%
    left: 0
    right: 0
    margin: auto
    top: 0
    bottom: 0
    background-color: $primary_one-lighter
    color: $primary_one
    z-index: 12

    &.lg
      background-size: 74px
      width: 74px
      height: 74px

    &.md
      background-size: 56px
      width: 56px
      height: 56px

    &.sm
      background-size: 20px
      width: 20px
      height: 20px

    &.light
      background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Light.svg")

    &.dark
      background-image: url("../assets/images/ProgressCircle_Indeterminate-14_Dark.svg")

      