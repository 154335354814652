
/*Form elements styling*/
.kn-input-contain
    position: relative
    line-height: 23px

    * 
        box-sizing: border-box

    ::placeholder
        font-style: italic
        color: $gray-dark

    *:focus
        outline: none

    .kn-field-validation
        position: fixed
        width: inherit
        display: flex
        justify-content: space-between
        font-family: 'Avenir-Book'
        word-break: break-all
        
        .counter-error
            color: $knDanger
            font-family: 'Avenir-heavy'

        .char-count
            position: relative
            color: $gray-darker
            font-size: 12px
            padding: 7px 0
            text-align: right

        .error
            position: relative
            color: $knDanger
            margin-top: 6px
            margin-right: 20px
            font-size: 12px

            i
                padding-right: 6px
                font-size: 16px

        .warning
            position: relative
            color: $secondary_one
            margin-top: 6px
            margin-right: 20px
            font-size: 12px

            i
                padding-right: 6px
                font-size: 16px

            .kn-input-message-text
                color: $ink

    .options
        padding: 0 25px

        .labels
            padding-left: 10px

    .inner
        display: flex
        flex-direction: column-reverse
        align-items: flex-start
        position: relative
        width: 100%

        .clear-icon, .search-icon, .password-icon
            position: absolute
            right: 10px
        
        .password-icon
            top: 29px

            &.disabled
                color: $gray-darker
                pointer-events: none

        .clear-icon
            top: 29px
            display: none

            &:hover
                display: block //hover state when focused (in order to be clicked)

            &.kn-sm
                top: 24px


        .search-icon
            top: 13px
            right: 15px
            
            &.kn-sm
                top: 7px

        .dropdown-icon
            position: absolute
            right: 10px
            bottom: 6px
            transition: $kn-transition-200
            cursor: pointer

            &.kn-sm
                bottom: 1px

            &.rotate
                transform: rotate(180deg)

        //dropdown input wrapper
        .kn-dropdown-wrapper
            position: relative

        .label
            display: inline-block
            font-size: 12px
            text-transform: uppercase
            color: $ink-light
            font-family: 'Avenir-book'
            margin-bottom: 0

            &.input-label-error
                color: $knDanger

            &.input-label-warning
                color: $secondary_one

            .optional-label
                margin-left: 4px
                text-transform: capitalize

        textarea
            resize: none
            overflow-y: scroll

        textarea::-webkit-scrollbar-thumb
            border: 2px solid #fff

        .kn-form-control
            color: $ink
            border: 1px solid $gray-darker
            font-size: 14px
            font-family: 'Avenir-book'
            line-height: 1.9
            display: block
            padding: 8px 37px 8px 10px
            font-weight: 400
            line-height: 1.5
            background-color: #fff
            background-clip: padding-box
            border-radius: 0.25rem
            width: 100%
            text-overflow: ellipsis

            &.kn-sm
                padding: 0px 37px 0px 10px
                font-size: 12px
                height: 28px

            &.input-warning
                outline: 2px solid $secondary_one
                border: 1px solid transparent

                &:focus, input:focus
                    outline: 2px solid $secondary_one
                    border: 1px solid transparent

            &:focus, input:focus
                outline: 2px solid $primary_one
                border: 1px solid transparent
        
            &.input-error
                outline: 2px solid $knDanger
                border: 1px solid transparent
                    
            &:read-only
                background: $gray-light
                border: 1px solid $gray-darker
                outline: none
                pointer-events: none
            
            &:read-only::-webkit-scrollbar-thumb
                 border: 2px solid $gray-light  
                 background-color: $gray-dark
                 
            &:read-only::-webkit-scrollbar-track
                 background: $gray-light  

        &:focus-within
            .label
                color: $primary_one

                &.input-label-warning
                    color: $secondary_one 

                &.input-label-error
                    color: $knDanger

            .clear-icon
                display: block
            
  
    &.disabled
        .inner
            .kn-form-control::-webkit-scrollbar-thumb
                background-color: $gray-dark
                border-color: $white

            .kn-form-control::-webkit-scrollbar-track
                background: $white

            .kn-form-control
                pointer-events: none
                border: 1px solid $gray-dark
                color: $gray-darker
                background: $white
            
            i
                color: $gray-darker
                pointer-events: none
                cursor: not-allowed

            .label
                color: $gray-darker
    
    &.readonly
        .inner
            i
                color: $gray-darker
                pointer-events: none
                cursor: not-allowed

            &:focus-within
                .label
                    color: $ink-light

    &.kn-sm
        .inner
            .clear-icon
                top: 1px

            .password-icon
                top: 1px

            .kn-form-control
                height: 30px
    
    &.no-label
        .inner
            .clear-icon
                top: 6px

                &.kn-sm
                    top: 1px

/*dropdown list*/
.kn-dropdown::-webkit-scrollbar-thumb
    border: 2px solid #fff
    
.kn-dropdown
    position: fixed
    height: max-content
    max-height: 210px
    min-width: 132px
    overflow-y: auto
    width: 100%
    background: $white
    border: 1px solid $border-color
    display: block
    border-radius: 4px
    box-shadow: $box-shadow-elv-4
    font-family: 'Avenir-book'
    font-size: 14px
    margin-top: 6px
    color: $ink
    visibility: hidden
    z-index: -10
    
    .no-results
        margin: 17px
        text-align: center

    &.kn-autocomplete-dropdown
        min-height: max-content

    &.show
        visibility: visible
        z-index: 1000

    .kn-dropdown-item:first-child
            margin-top: 10px

    .kn-dropdown-item:last-child
            margin-bottom: 10px
        
    .kn-dropdown-item
        padding: 8px 12px
        border-top: 1px solid $white
        border-bottom: 1px solid $white
        
        &:hover, &.focus, &:focus
            @include dropdownHover

        &.active
            cursor: pointer
            color: $primary_one

            &:hover
                @include dropdownHover(var(--primary_one))
                text-shadow: none

        &.disabled
            pointer-events: none
            opacity: .45
            border: none
            background: $white
            font-family: 'Avenir-Book'

        
.kn-popup
    position: absolute
    z-index: 9999
    background: $white
