
@mixin dropdownHover($color: $ink)
    cursor: pointer
    border-top: 1px solid $primary_one-med-light !important
    border-bottom: 1px solid $primary_one-med-light !important
    background: $blue-lighter !important
    font-family: 'Avenir-Book'
    @include textHover($color)
    outline: none

    i
        text-shadow: none

@mixin textHover($color: $ink)
    font-weight: 900
    color: $color

@mixin tooltipContainer()
    text-align: left
    z-index: 1010
    position: fixed
    padding: 10px 20px
    font-size: 14px
    line-height: initial
    color: white
    width: auto
    background: $ink-light
    border-radius: 4px
    box-sizing: border-box
    opacity: 0
    transform: translate(-50%, -30%)
    animation: tooltip-slide .25s
    animation-fill-mode: forwards
    pointer-events: none
    box-shadow: $box-shadow-elv-2
    max-width: 254px
    font-family: 'Avenir-book'
    word-wrap: break-word
    
@mixin tooltipArrowDown()
    margin-top: 12px
    
    &::after
        content: ""
        position: absolute
        top: 100%
        left: 50%
        border-width: 7px
        border-style: solid
        border-color: $ink-light transparent transparent transparent
        transform: translateX(-50%)

@mixin syncFusionDialogFooter($borderTop)
    display: flex
    justify-content: flex-end
    border-top: $borderTop
    border-bottom-right-radius: 4px
    border-bottom-left-radius: 4px
    flex-direction: unset

    .e-btn
        margin: 10px
    
    .e-btn[disabled]
        &.e-primary
            @include disabledBtn

    @include btnClass()
    
@mixin btnClass()
    .e-btn
        display: flex
        align-items: center
        justify-content: center
        border-radius: 4px
        transition: $kn-transition-250
        width: 135px
        height: 36px
        padding: 7px 16px
        cursor: pointer
        text-transform: uppercase
        font-size: $primary-btn-font-size
        font-family: 'Avenir-Heavy'
        text-decoration: none !important
        
        &.e-primary
            border: 1px solid $primary-one-darker
            order: 1
            background: $primary-one-darker
            color: $white
            min-width: 108px

            &:hover
                border: 1px solid $primary-one
                background: $primary-one
                color: $white

    .e-flat
        color: $primary-one
        font-weight: bold
        transition: $kn-transition-200
        width: 135px
        padding: 9px 16px
        cursor: pointer
        text-transform: uppercase
        font-size: $flat-btn-font-size
        display: flex
        align-items: center
        border-radius: 4px
        font-family: 'Avenir-Heavy'
        font-size: $primary-btn-font-size
        text-decoration: none !important
        min-width: 108px
        justify-content: center

        &:hover
            background: $flat-btn-hover-color

@mixin disabledBtn()
    background: $gray-light
    border-color: $gray-light 
    color: $gray-darker 
    pointer-events: none

@mixin knIcon($width: 28px)
    cursor: pointer
    border-radius: 50% !important
    transition: .1s
    padding: 6px
    font-size: 16px
    text-align: center
    color: $ink-light
    height: 28px
    width: $width
    box-sizing: border-box

    &.kn-icon-md
        height: 32px
        width: 32px

    &.kn-icon-dark
        color: $white

        &:hover
            background: $ink-lighter 
            color: $white

    &:hover
        background: $primary-one-light
        color: $primary-one
    
//DateTime calendar highlight (scynFusion grid filter)
@mixin scynFusionCalendarHighlight()
    background-color: var(--primary_one-lighter)
    color: var(--primary_one)
    font-weight: 900
    box-shadow: none
    border: none
    border-radius: 50%
    
@mixin inputSelectedText() 
    background-color: var(--primary_one-lighter) !important
    color: var(--ink) !important

@mixin dialogBorder()
    border: 1px solid var(--border-color)
    border-bottom-left-radius: var(--border-radius)
    border-bottom-right-radius: var(--border-radius)
    border-top-left-radius: var(--border-radius)
    border-top-right-radius: var(--border-radius)
    
@mixin label()
    text-transform: uppercase
    font-family: 'Avenir-Book'
    font-size: 12px
    
@mixin labelFocus()
    color: var(--primary_one-dark)
    